import { action, makeAutoObservable } from "mobx";
import ElementModel from "../../model/ElementModel";
import { RootStore } from "../index";

export class PageMainStore {
  private rootStore: RootStore | undefined;
  arItemsNew = [];
  arItemsSpecials = [];
  arItems = [];
  public error: string = "";

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  init() {
    if (this.rootStore?.mainStore.isAuth) {
      ElementModel.getCatalog(
        {
          json: {
            clientId: this.rootStore?.mainStore.getClientId(),
            limit: 10,
            filter: { props: { isNovelty: true } },
          },
          page: 1,
        },
        this.setCatalogNew,
        this.setError
      );

      // if (true) {
        ElementModel.getBestsellers(
          {
            clientId: this.rootStore?.mainStore.getClientId(),
            categoryId: 0,
          },
          this.setCatalogSpecials,
          this.setError
        );
      // }
      // else {
      //   ElementModel.getCatalog(
      //     {
      //       json: {
      //         clientId: this.rootStore?.mainStore.getClientId(),
      //         limit: 10,
      //         filter: { props: { isNovelty: true } },
      //       },
      //       page: 1,
      //     },
      //     this.setCatalogSpecials,
      //     this.setError
      //   );
      // }
    }
  }

  @action
  setCatalogNew = (result: any) => {
    this.arItemsNew = result.data.rows;
  };

  @action
  setCatalogSpecials = (result: any) => {
    this.arItemsSpecials = result.data.rows;
  };

  @action
  setCatalog = (result: any) => {
    this.arItems = result.data.rows;
  };

  @action
  setError = (error: any) => {
    console.log(error);
  };
}

import React from 'react';
import { HeaderMenu } from "./HeaderMenu";
import { SearchTopFormLeft } from "../block/SearchTopFormLeft";
import { withStore } from "../../hocs";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { WIDTH } from "../../const/root";

// @ts-ignore
@withStore
@observer
export class HeaderTop extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const { store } = this.props;
        store?.appStore.init();
        store?.mainStore.getLoyalty();
        store?.pageMassageStore.init()
        store?.pagePersonalStore.getInfoLoyaltyID()
    }
    render() {
        // @ts-ignore
        const { store } = this.props;
        let {
            arFilterCatalogEmun,
            menuHeader,
            listMenuCatalog,
            getFilterSection,
            onChecks,
            isOpenMenuCatalog,
            filterActive,
            closeMenu,
            closeMenuAction,
            showSkeleton,
            listMenuCatalogNew,
            showArray,
            newObj
        } = store?.appStore;

        const {
            onPageBrandCheckedMobile,
            isOpenSubMenuSubCatalog,
        } = store?.componentFilterCatalogStore;
        const {
            scoresAmount,
            includeUserLoyaltyProgram
        } = store?.mainStore;
        const { countShowTop } = store?.pageMassageStore;

        const { isAuth, url, name, updateCookies, showCookies } = store.mainStore;

        const {
            arBasket,
        } = store?.pageBasketStore;
        const {
            arFavorite,
        } = store?.pageCatalogStore;

        let Width = window.innerWidth;

        let show = closeMenu

        if (listMenuCatalogNew.length && filterActive[12511]) {
            listMenuCatalogNew = [listMenuCatalogNew[1], listMenuCatalogNew[0], listMenuCatalogNew[2]]
        }

        return (
            <>
                <header className="page__header page-header js--page-header">
                    <div className="container page-header__grid">
                        <div className="page-header__wrapper">
                            <div className="page-header__logo-wrapper">
                                <Link className="logo" to="/">
                                    <img className="logo__icon" src="/images/pic--logo-new.png" alt='' />
                                </Link>
                            </div>
                            <button className="nav-toggle js--nav-toggle " onClick={closeMenuAction} type="button"><span
                                className="visually-hidden">Показать меню</span>
                                <span className="nav-toggle__icon" />
                            </button>
                            {isAuth ?
                                <ul className="user-menu">
                                    <li className="user-menu__item user-link"><a href="/personal/data"><span className="visually-hidden">Личный кабинет</span>
                                        <svg className="user-link__icon" width="22" height="22">
                                            <use xlinkHref="/images/sprite.svg#icon--user" />
                                        </svg>
                                    </a></li>
                                    {isAuth ?
                                        <>
                                            <li className={Width < 1000 ? "user-menu__item user-link" : "user-menu__item user-link user-link--is-hidden"}>
                                                <Link to="#"
                                                    onClick={(e) => store.authStore.logout()}>
                                                    <span className="visually-hidden">Выйти</span>
                                                    <svg className="user-link__icon" width="19" height="19">
                                                        <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                    </svg>
                                                </Link>
                                            </li>
                                        </>
                                        : null
                                    }
                                    <li className="user-menu__item personal-link personal-link--desktop">
                                        <svg className="personal-link__icon" width="21" height="21">
                                            <use xlinkHref="/images/sprite.svg#icon--user" />
                                        </svg>
                                        <span className="personal-link__content">
                                            <Link className="personal-link__link" to="/personal/data">{name}</Link>

                                            <Link className="personal-link__logout" to="#">
                                                <svg className="personal-link__logout-icon" width="16" height="16">
                                                    <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                </svg>Выйти
                                            </Link>
                                        </span>
                                    </li>

                                    {includeUserLoyaltyProgram ?
                                        <li className="user-menu__item">
                                            <a className="link-indicator" href="/personal/loyalty">
                                                <span className="link-indicator__counter" style={String(scoresAmount).length > 4 ? { width: (25 + Math.round(String(scoresAmount).length / 5) * 25), borderRadius: "50%" } : {}}>{scoresAmount}</span>
                                                <img className="link-indicator__icon" width="25" height="23" src="/images/korobochka_wh.png"
                                                    style={{ marginTop: 10 }} alt='' />
                                            </a>
                                        </li> : null}
                                    <li className="user-menu__item">
                                        <a className="link-indicator" href="/favorite">
                                            <span className="link-indicator__counter">{Array.isArray(arFavorite) ? arFavorite.length : 0}</span>
                                            <svg className="link-indicator__icon" width="16" height="23">
                                                <use xlinkHref="/images/sprite.svg#icon--favorite" />
                                            </svg>
                                        </a>
                                    </li>

                                    <li className="user-menu__item">
                                        <a className="link-indicator" href="/basket">
                                            <span className="link-indicator__counter">{Array.isArray(arBasket) ? arBasket.length : 0}</span>
                                            <svg className="link-indicator__icon" width="20" height="24">
                                                <use xlinkHref="/images/sprite.svg#icon--cart" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                                :
                                <ul className="user-menu">
                                    {isAuth ?
                                        null
                                        :
                                        <>
                                            <li className={Width < 1000 ? "user-menu__item user-link" : "user-menu__item user-link user-link--is-hidden"}>
                                                <Link to="#"
                                                    onClick={(e) => store.appStore.setPopupAuthorization(true)}>

                                                    <span>
                                                        <svg style={{}} className="user-link__icon" width="19" height="19">

                                                            <use xlinkHref="/images/sprite.svg#icon--user" />
                                                        </svg>
                                                    </span>

                                                    <span style={{ color: "white", paddingRight: 30 }}>Вход</span>
                                                </Link>
                                            </li>
                                        </>
                                    }
                                </ul>
                            }
                            {isAuth ? <>
                                <SearchTopFormLeft />
                            </> : null}
                        </div>

                        {Width > WIDTH ?
                            <div className="page-header__overlay js--nav-overlay">
                                <Link className="logo logo--desktop" to="/">
                                    <img className="logo__icon" src="/images/pic--logo-new.png" alt='' />
                                </Link>
                                <button style={{ display: "none" }} className="page-header__overlay-close js--overlay-close" type="button"><span
                                    className="visually-hidden">Закрыть меню</span>
                                    <svg className="page-header__overlay-close-icon" width="25" height="25">
                                        <use xlinkHref="/images/sprite.svg#icon--close" />
                                    </svg>
                                </button>
                                <nav className="page-header__navigation">
                                    {/* header меню */}
                                    <HeaderMenu store={store}
                                        menu={menuHeader}
                                        url={url}
                                        isAuth={isAuth}
                                        arFilterCatalogEmun={arFilterCatalogEmun}
                                        onChecks={onChecks}
                                        filterActive={filterActive}
                                        getFilterSection={getFilterSection}
                                        isOpenMenuCatalog={isOpenMenuCatalog}
                                        listMenuCatalog={listMenuCatalog}
                                        isOpenSubMenuSubCatalog={isOpenSubMenuSubCatalog}
                                        onPageBrandCheckedMobile={onPageBrandCheckedMobile}
                                        includeUserLoyaltyProgram={includeUserLoyaltyProgram}
                                        showSkeleton={showSkeleton}
                                        listMenuCatalogNew={listMenuCatalogNew}
                                        showArray={showArray}
                                        newObj={newObj}
                                    />
                                    {isAuth ? <div className="page-header__search">
                                        <SearchTopFormLeft />
                                    </div> : <div className="page-header__search">
                                        <p style={{ opacity: 0 }}>
                                            просто втавил пэшку
                                        </p>
                                    </div>}

                                </nav>
                                <div className="page-header__contacts">
                                    <p className="header-phone">
                                        <a className="header-phone__number" href="tel:+375(44)748-08-23" >
                                            <svg className="header-phone__number-icon" width="22" height="22">
                                                <use xlinkHref="/images/sprite.svg#icon--phone" />
                                            </svg>
                                            +375 (44) 748-08-23
                                        </a>
                                        <a className="header-phone__telegram" href="https://t.me/+375447480823"><span
                                            className="visually-hidden">Открыть Telegram</span>
                                            <svg className="header-phone__telegram-icon" width="21" height="18">
                                                <use xlinkHref="/images/sprite.svg#icon--telegram" />
                                            </svg>
                                        </a>
                                        <a className="header-phone__viber" href="viber://chat?number=375447480823"><span className="visually-hidden">Открыть Viber</span>
                                            <svg className="header-phone__viber-icon" width="18" height="20">
                                                <use xlinkHref="/images/sprite.svg#icon--viber" />
                                            </svg>
                                        </a></p>

                                </div>
                                <ul className="user-menu">
                                    <li className="user-menu__item personal-link personal-link--desktop" key={'link-desktop'}>
                                        {isAuth ?
                                            <>
                                                <svg className="personal-link__icon" width="21" height="21">
                                                    <use xlinkHref="/images/sprite.svg#icon--user" />
                                                </svg>
                                                <span className="personal-link__content">
                                                    <Link className="personal-link__link" to="/personal/data">{name}</Link>
                                                    <Link className="personal-link__logout  js--open-modal"
                                                        to="#"
                                                        onClick={(e) => store.authStore.logout()}>
                                                        <svg className="personal-link__logout-icon" width="16" height="16">
                                                            <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                        </svg>Выход
                                                    </Link>
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className="personal-link__content">
                                                    <Link className="personal-link__logout  js--open-modal"
                                                        onClick={(e) => store.appStore.setPopupAuthorization(true)}
                                                        to="#"
                                                        data-modal="authorization">
                                                        <svg className="personal-link__logout-icon" width="16" height="16">
                                                            <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                        </svg>Вход
                                                    </Link>
                                                </span>
                                            </>
                                        }
                                    </li>
                                    {isAuth ?
                                        <>
                                            {includeUserLoyaltyProgram ?
                                                <Tooltip title="Бонусные баллы">
                                                    <li className="user-menu__item">
                                                        <a className="link-indicator" href="/personal/loyalty">
                                                            <span className="link-indicator__counter" style={String(scoresAmount).length > 4 ? { width: (25 + Math.round(String(scoresAmount).length / 5) * 25), borderRadius: "50%" } : {}}>{scoresAmount}</span>
                                                            <img className="link-indicator__icon" width="25" height="23" src="/images/color_bb.png"
                                                                style={{ marginTop: 10 }} alt='' />
                                                        </a>
                                                    </li>
                                                </Tooltip>
                                                : null}
                                            <Tooltip title="Сообщения">
                                                <li className="user-menu__item">
                                                    <a className="link-indicator" href="/personal/news">
                                                        <span className="link-indicator__counter">{countShowTop}</span>
                                                        <img style={{ width: 27, paddingTop: 10 }} src="/images/Mail.svg" alt=''
                                                        />
                                                    </a>
                                                </li>
                                            </Tooltip>
                                            <Tooltip title="Избранные товары">
                                                <li className="user-menu__item" key={'user-menu'}><a className="link-indicator" href="/favorite"><span
                                                    className="link-indicator__counter">{Array.isArray(arFavorite) ? arFavorite.length : 0}</span>
                                                    <svg className="link-indicator__icon" width="16" height="23">
                                                        <use xlinkHref="/images/sprite.svg#icon--favorite" />
                                                    </svg>
                                                </a></li>
                                            </Tooltip>
                                            <Tooltip title="Товаров в корзине">
                                                <li className="user-menu__item" key={'user-menu-2'}>
                                                    <Link className="link-indicator" to="/basket"><span
                                                        className="link-indicator__counter">{Array.isArray(arBasket) ? arBasket.length : 0}</span>
                                                        <svg className="link-indicator__icon" width="20" height="24">
                                                            <use xlinkHref="/images/sprite.svg#icon--cart" />
                                                        </svg>
                                                    </Link></li>
                                            </Tooltip>
                                        </>
                                        :
                                        null
                                    }
                                </ul>
                            </div>

                            : null}

                        {show && Width < WIDTH ?
                            <div >
                                <Link className="logo logo--desktop" to="/">
                                    <img className="logo__icon" src="/images/pic--logo-new.png" alt='' />
                                </Link>
                                <button style={{ zIndex: 1 }} className="page-header__overlay-close js--overlay-close" onClick={closeMenuAction} type="button"><span
                                    className="visually-hidden">Закрыть меню</span>
                                    <svg className="page-header__overlay-close-icon" width="25" height="25">
                                        <use xlinkHref="/images/sprite.svg#icon--close" />
                                    </svg>
                                </button>
                                <nav className="page-header__navigation">
                                    {/* header меню */}
                                    <HeaderMenu store={store}
                                        menu={menuHeader}
                                        url={url}
                                        isAuth={isAuth}
                                        arFilterCatalogEmun={arFilterCatalogEmun}
                                        onChecks={onChecks}
                                        filterActive={filterActive}
                                        getFilterSection={getFilterSection}
                                        isOpenMenuCatalog={isOpenMenuCatalog}
                                        listMenuCatalog={listMenuCatalog}
                                        isOpenSubMenuSubCatalog={isOpenSubMenuSubCatalog}
                                        onPageBrandCheckedMobile={onPageBrandCheckedMobile}
                                        includeUserLoyaltyProgram={includeUserLoyaltyProgram}
                                        showSkeleton={showSkeleton}
                                        listMenuCatalogNew={listMenuCatalogNew}
                                        showArray={showArray}
                                        newObj={newObj}
                                    />
                                    {isAuth ? <div className="page-header__search">
                                        <SearchTopFormLeft />
                                    </div> : null}
                                    <div className="page-header__search">
                                        <SearchTopFormLeft />
                                    </div>
                                </nav>
                                <div className="page-header__contacts">
                                    <p className="header-phone">
                                        <a className="header-phone__number" style={Width < WIDTH ? { fontSize: 15 } : {}} href="tel:+375(44)748-08-23">
                                            <svg className="header-phone__number-icon" width="22" height="22">
                                                <use xlinkHref="/images/sprite.svg#icon--phone" />
                                            </svg>
                                            +375 (44) 748-08-23</a>
                                        <a className="header-phone__telegram" href="https://t.me/+375447480823"><span
                                            className="visually-hidden">Открыть Telegram</span>
                                            <svg className="header-phone__telegram-icon" width="21" height="18">
                                                <use xlinkHref="/images/sprite.svg#icon--telegram" />
                                            </svg>
                                        </a>
                                        <a className="header-phone__viber" href="viber://chat?number=375447480823"><span className="visually-hidden">Открыть Viber</span>
                                            <svg className="header-phone__viber-icon" width="18" height="20">
                                                <use xlinkHref="/images/sprite.svg#icon--viber" />
                                            </svg>
                                        </a></p>
                                </div>
                                <ul className="user-menu">
                                    <li className="user-menu__item personal-link personal-link--desktop" key={'link-desktop'}>
                                        {isAuth ?
                                            <>
                                                <svg className="personal-link__icon" width="21" height="21">
                                                    <use xlinkHref="/images/sprite.svg#icon--user" />
                                                </svg>
                                                <span className="personal-link__content">
                                                    <Link className="personal-link__link" to="/personal/data">{name}</Link>
                                                    <Link className="personal-link__logout  js--open-modal"
                                                        to="#"
                                                        onClick={(e) => store.authStore.logout()}>
                                                        <svg className="personal-link__logout-icon" width="16" height="16">
                                                            <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                        </svg>Выход
                                                    </Link>
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className="personal-link__content">
                                                    <Link className="personal-link__logout  js--open-modal"
                                                        onClick={(e) => store.appStore.setPopupAuthorization(true)}
                                                        to="#"
                                                        data-modal="authorization">
                                                        <svg className="personal-link__logout-icon" width="16" height="16">
                                                            <use xlinkHref="/images/sprite.svg#icon--logout" />
                                                        </svg>Вход
                                                    </Link>
                                                </span>
                                            </>
                                        }
                                    </li>
                                </ul>
                            </div>
                            : null}

                    </div>
                </header>
                {showCookies ? <>
                    <div style={{ position: "fixed", backgroundColor: "white", width: "100%", bottom: 0, zIndex: 9999, border: "2px solid #d1a0a9", padding: 10 }}>
                        <div style={{ textAlign: "center" }}>
                            Сайт www.beautyhouse.by использует файлы cookies и сервисы сбора технических данных посетителей
                            (данные об IP-адресе, местоположении и др.) для обеспечения работоспособности и улучшения качества
                            обслуживания. Продолжая использовать наш сайт, вы автоматически соглашаетесь с использованием
                            данных технологий. Кликните «Принять и закрыть», чтобы согласиться с использованием «cookies» и
                            больше не отображать это предупреждение.
                        </div>
                        <div style={{ display: "flex", margin: "auto", alignItems: "center", justifyContent: "center" }} >
                            <Link to="/privacy-policy">
                                <button style={{ padding: 10, margin: 10 }} >
                                    Узнать больше
                                </button>
                            </Link>
                            <button style={{ padding: 10, margin: 10 }} onClick={() => updateCookies()}>
                                Принять и закрыть
                            </button>
                        </div>
                    </div>
                </> : null}

            </>
        );
    }
}



import React from 'react';
import { PersonalMenu } from "../../components/personal/PersonalMenu";
import { PersonalPhoto } from "../../components/personal/PersonalPhoto";
import { withStore } from "../../hocs";
import { observer } from "mobx-react";
import { AnyQuestions } from "../../components/block/AnyQuestions";
import moment from "moment";


// @ts-ignore
@withStore
@observer
export default class NewsPersonal extends React.Component {
    componentDidMount() {
        // @ts-ignore
        const { store } = this.props;
        store?.pageMassageStore.init();
    }

    render() {
        // @ts-ignore
        const { store } = this.props;

        const {
            arItems,
            setOpen,
            allRead,
        } = store?.pageMassageStore;

        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item">История заказов</li>
                        </ul>
                        <h1 className="page__title">Сообщения</h1>

                        <div className="page__main-grid">
                            <div className="page__main-column">
                                <div className="private-content">
                                    <div className="private-content__header">
                                        <h2 className="private-content__title">Сообщения</h2>
                                        <div >
                                            <button className="button button--accent-dark cart__detail-button" onClick={() => allRead()} >Прочитать все</button>
                                        </div>
                                    </div>

                                    <div className="order-history js--accordion">

                                        <div className="order-history__header">
                                            <span className="order-history-item__header-label order-history-item__header-label--order" style={{ width: "20%" }}>Статус сообщений</span>
                                            <span className="order-history-item__header-label order-history-item__header-label--summ" style={{ width: "20%" }}>Дата</span>
                                            <span className="order-history-item__header-label order-history-item__header-label--is-payed" style={{ width: "60%" }}>Тема</span>
                                        </div>

                                        {arItems.map((item: any, index: number) => (
                                            <>
                                                {item.props.Sites[0] === 4 ?
                                                    <div className="order-history-item" key={index}>

                                                        <div className={item.isOpen ? "order-history-item__preview js--accordion-toggle is-active" : "order-history-item__preview js--accordion-toggle"} onClick={() => setOpen(item)} >
                                                            <span className="order-history-item__preview-label order-history-item__preview-label--order" style={{ width: "20%" }}>{item.isRead ? "Прочитано" : <p style={{ color: "#d1a0a9" }}>Не прочитано</p>}</span>
                                                            <span className="order-history-item__preview-label order-history-item__preview-label--status" style={{ width: "20%" }}>{moment(item.createdAt).format("DD.MM.YYYY")}</span>
                                                            <span className="order-history-item__preview-label order-history-item__preview-label--summ" style={{ width: "60%" }}>{item.name}</span>
                                                            <button
                                                                onClick={() => store?.pageOrderStore.showProdDetail(item.id)}
                                                                className="order-history-item__preview-toggle"
                                                                type="button">
                                                                <span className="visually-hidden">Открыть/Закрыть</span>
                                                            </button>
                                                        </div>

                                                        {item.isOpen ?
                                                            <div className="history-goods">
                                                                <div style={{ display: "flex", justifyContent: "space-between" }} className="order-history__detail___params">

                                                                    <ul className="order-history__params">
                                                                        <li className="order-history__params-item">
                                                                            <span dangerouslySetInnerHTML={{ __html: item.propsNoFilter.Html }} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div> : null}
                                                    </div> : null

                                                }
                                            </>
                                        ))}

                                    </div>

                                </div>
                            </div>
                            <aside className="page__aside">
                                <div className="user-account">
                                    <PersonalPhoto />
                                    <PersonalMenu />
                                </div>
                            </aside>
                        </div>
                    </div>

                    <AnyQuestions />

                </main>
            </>
        )
    }
}

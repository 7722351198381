import React from 'react';


interface Props {
    isPopupAccessDenied: boolean;
    setPopupAuthorization: any;
    setPopupAccessDenied: any;
}

export class AccessDenied extends React.Component<Props, {}> {
    render() {

        // @ts-ignore
        let isPopupAccessDenied = this.props.isPopupAccessDenied;
        let setPopupAuthorization = this.props.setPopupAuthorization;
        let setPopupAccessDenied = this.props.setPopupAccessDenied;


        return (
            <>
                <div className={isPopupAccessDenied ? "modal is-open" : "modal"} data-modal="accessdenied">
                    <a href="/">
                        <button className="modal__close js--modal-close"
                                type="button">
                            <span className="visually-hidden">Закрыть модальное окно</span>
                            <span className="modal__close-icon" />
                        </button>
                    </a>
                    <div className="modal__body">
                        <h2 className="modal__title">Этот раздел доступен только для авторизованных клиентов</h2>
                        <p className="modal__body-text">Портал BEAUTYHOUSE.BY работает исключительно с профессионалами бьюти
                            индустрии и не обслуживает физических лиц</p>
                    </div>
                    <div className="modal__footer">
                        <p className="modal__sub-title">Еще не наш клиент?</p>
                        <p>Для авторизации необходимо подать заявку на сотрудничество</p>
                        <a className="button button--accent-dark modal__button" href="/cooperation">Подать заявку</a>
                        <br/>
                        <br/>
                        <div className="button button--accent-dark modal__button" onClick={() => { 
                            setPopupAuthorization(true); 
                            setPopupAccessDenied(false); 
                        }} style={{cursor: "pointer"}} >Войти</div> </div>
                </div>

            </>
        )
    }
}

import React from 'react';
import {WIDTH} from "../../const/root";

export class MainWeInstagram extends React.Component {

    render() {
        let Width = window.innerWidth
        return (
            <>
                <section className="section instagram">
                    <div className="container">
                        <h2 className="section__title">Мы в instagram</h2>
                        <p className="instagram__accounts">
                            <img style={{paddingRight: 5}} alt='' width="25px"
                                 src="/images/instagram.svg" /><a href="https://www.instagram.com/belarus_kerastase/" target="_blank" rel="noreferrer">@belarus_kerastase</a>
                            <img style={{paddingRight: 5}} alt='' width="25px"
                                 src="/images/instagram.svg" /> <a href="https://www.instagram.com/lorealpro_education_belarus/" target="_blank" rel="noreferrer">@lorealpro_education_belarus</a>
                            <img style={{paddingRight: 5}} alt='' width="25px"
                                 src="/images/instagram.svg" /><a href="https://www.instagram.com/belarusmx/" target="_blank" rel="noreferrer">@belarusmx</a>
                            <img style={{paddingRight: 5}} alt='' width="25px"
                                 src="/images/instagram.svg" /><a href="https://www.instagram.com/belarus_redken/" target="_blank" rel="noreferrer">@belarus_redken</a>
                        </p>

                        {Width < WIDTH ?  <p
                            style={{alignItems: "center",justifyContent: "center",marginBottom: '20px',fontSize: '16px',lineHeight: '100%'}}
                        >
                            <p>
                                <img style={{paddingRight: 5}} alt='' width="25px"
                                     src="/images/instagram.svg" /> <a  style={{borderBottom: '2px solid #f3e5e8', height: 20}} href="https://www.instagram.com/belarus_kerastase/" target="_blank" rel="noreferrer">@belarus_kerastase</a>
                            </p>
                            <p>
                                <img style={{paddingRight: 5}} alt='' width="25px"
                                     src="/images/instagram.svg" /> <a  style={{borderBottom: '2px solid #f3e5e8', height: 20}} href="https://www.instagram.com/lorealpro_education_belarus/" target="_blank" rel="noreferrer">@lorealpro_education_belarus</a>
                            </p>
                            <p>
                                <img style={{paddingRight: 5}} alt='' width="25px"
                                     src="/images/instagram.svg" /> <a  style={{borderBottom: '2px solid #f3e5e8', height: 20}} href="https://www.instagram.com/belarusmx/" target="_blank" rel="noreferrer">@belarusmx</a>
                            </p>
                            <p>
                                <img style={{paddingRight: 5}} alt='' width="25px"
                                     src="/images/instagram.svg" /> <a  style={{borderBottom: '2px solid #f3e5e8', height: 20}} href="https://www.instagram.com/belarus_redken/" target="_blank" rel="noreferrer">@belarus_redken</a>
                            </p>

                        </p> : null}

                    </div>
                    <ul className="instagram__gallery">
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-1.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-2.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-3.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-4.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-5.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-6.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-7.jpg" /></li>
                        <li className="instagram__gallery-item"><img alt='' className="instagram__gallery-thumbnail"
                                                                     src="/images/pic--insta-8.jpg" /></li>
                    </ul>
                </section>
            </>
        )
    }
}

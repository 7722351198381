import React from 'react';
import { GoodsItem } from "./GoodsItem";
import { PaginationCatalog } from "./PaginationCatalog";

interface Props {
    items: [];
    view: string
    countPage: number,
    currentPage: number,
    onCurrentPage: (page: number) => void,
    showSkeleton: boolean,
}

export class RenderGoods extends React.Component<Props, {}> {

    render() {

        let arData = this.props.items;
        let view = this.props.view;
        let countPage = this.props.countPage;
        let onCurrentPage = this.props.onCurrentPage;
        let currentPage = this.props.currentPage;
        let showSkeleton = this.props.showSkeleton;

        return (
            <>
                <div className={"goods-catalog__list " + view}>
                    {arData ? arData.map((item: any, index) => (
                        <GoodsItem key={index} item={item} width="1" type={"Any"} />
                    )) : null}
                    <PaginationCatalog countPage={countPage} onCurrentPage={onCurrentPage} currentPage={currentPage} showSkeleton={showSkeleton} />
                </div>
            </>
        );
    }
}

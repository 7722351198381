import React from 'react';
import { AnyQuestions } from "../components/block/AnyQuestions";
import { withStore } from "../hocs";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { YOUR_TRACKING_ID } from "../stores/AppStore";
import { withRouter } from "react-router";

// @ts-ignore
@withStore
@observer

class SingleBrandPage extends React.Component {
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    componentDidMount() {
        // @ts-ignore
        const { store } = this.props;
        // @ts-ignore
        window.scrollTo(0, 0)
        this.setGA();
        // @ts-ignore
        const code = this.props.match.params.code;
        store?.brandsStore.setDetailBrand(code);
    }

    render() {
        // @ts-ignore
        const { store } = this.props;

        let isAuth = store.mainStore.isAuth;
        if (!isAuth) {
            store.appStore.setPopupAccessDenied(true)
        }
        const {
            singlesBrand,
            singlesBrandAfterSort
        } = store?.brandsStore;

        // @ts-ignore
        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/brands">Бренды</a></li>
                            <li className="breadcrumbs__item"> Бренд {singlesBrand.name}</li>
                        </ul>
                        <h1 className="page__title">Бренд {singlesBrand.name} </h1>
                        <img className="brands__thumbnail" src={singlesBrand.detail_img} alt='' />
                        <div style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: singlesBrand.detail_text }} />
                        <div className="brands brands--page" style={{ paddingBottom: 10, }}>
                            <ul className="brands__list" style={{ justifyContent: "space-around" }}>
                                {singlesBrandAfterSort.length ? singlesBrandAfterSort.map((item: any, index: any) => (
                                    <div key={index} style={{ width: "25%", border: "1px solid #e6ebef", margin: 10 }} >
                                        <Link to={item.name.split('#')[1]}>
                                            <div style={{ margin: "auto", alignItems: "center", justifyContent: "center", display: "flex", padding: 10, height: 150 }}>
                                                <img src={item.img} alt={item.name} width="40%" />
                                            </div>
                                            <h3 style={{ marginLeft: 10 }} >
                                                {item.name.split('#')[0]}
                                            </h3>
                                            <div dangerouslySetInnerHTML={{ __html: item.text }} style={{ margin: 10 }} />
                                        </Link>
                                    </div>
                                )) : null}
                            </ul>
                        </div>
                    </div>
                    <AnyQuestions />
                </main>
            </>
        )
    }
}

// @ts-ignore
export default withRouter(SingleBrandPage);
import React from 'react';
import { observer } from "mobx-react";
import { withStore } from "../../hocs";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { WIDTH } from "../../const/root";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Link, Redirect } from "react-router-dom"
import { Skeleton } from "@mui/material";


// @ts-ignore
@withStore
@observer
export class SearchTopFormLeft extends React.Component {

    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/img/no-image.webp'
    }

    constructor(props: any) {
        super(props);
        //init the state
        this.state = {
            redirect: false
        }
    }

    handleOnClick = () => {
        this.setState({ redirect: true });
    }

    render() {

        // @ts-ignore
        const { store } = this.props;

        const {
            onChangeInput,
            searchResult,
            onClearAndRemoveSearchInput,
            isOpenMenuSearch,
            section,
            onPopupSearchInput,
            showSkeletonSearchResult
        } = store?.pageCatalogStore;
        const { search } = store?.pageCatalogStore;
        let Width = window.innerWidth

        // @ts-ignore
        if (this.state.redirect) {
            this.setState({ redirect: false })
            onPopupSearchInput()
            return <Redirect push to="/catalog?1" />;
        }

        return (
            <>
                <Paper
                    elevation={0}
                    style={{ borderRadius: '20px', background: "#4A4F5B", height: '70%', margin: "auto" }}
                    component="form"
                    sx={Width < WIDTH ? { p: '0px 4px', display: 'flex', width: 320 } : { p: '0px 4px', display: 'flex', width: 250 }}
                >
                    <IconButton onClick={() => {
                        this.handleOnClick();
                        onPopupSearchInput()
                    }} sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon style={{ color: '#b17882', opacity: '1' }} />
                    </IconButton>
                    <InputBase
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                this.handleOnClick()
                                onPopupSearchInput()
                            }
                        }}
                        onChange={(e) => onChangeInput(e)} name="q" id="search__field" value={search}
                        sx={{ ml: 1, flex: 1, webkitAppearance: "none !important" }}
                        placeholder="Поиск"
                        style={{ fontStyle: 'italic', color: "white", }}
                        autoComplete="off"
                        type="search"
                    />
                    <IconButton onClick={onClearAndRemoveSearchInput} sx={{ p: '10px' }} aria-label="delete">
                        <ClearIcon style={{ color: '#b17882' }} />
                    </IconButton>
                </Paper>
                <div className={isOpenMenuSearch ? "main-menu__sub-catalog-wrapper  is-open" : "main-menu__sub-catalog-wrapper "} style={Width < 1023.99 ? { top: 165, height: 350, width: "100%", paddingRight: 30 } : {}}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} style={{ marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 3 }}>
                            <Grid item xs={1} md={1} lg={1} spacing={1} style={{ marginRight: 5, fontWeight: "bold" }}>
                                <div style={{ width: 105 }}>Категории &#62; </div>
                            </Grid>
                            <Grid item xs={11} md={11} lg={2}
                                onClick={() => {
                                    onClearAndRemoveSearchInput();
                                    onPopupSearchInput()
                                }}
                            >
                                {showSkeletonSearchResult ?
                                    <Stack spacing={1}>
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                    </Stack>
                                    : <>
                                        {searchResult && searchResult.sections && searchResult.sections.length && section.length ? section.map((item: any, index: number) => (
                                            <div >
                                                <Link style={{ cursor: "pointer" }} onClick={() => {onClearAndRemoveSearchInput(); onPopupSearchInput()}} to={"/catalog/filter/beautyHouseCategory-in-" + item.id} key={index}>{item.name}</Link>
                                            </div>
                                        )) : null
                                        }
                                    </>}
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} style={{ marginRight: 5, fontWeight: "bold" }}>
                                <div style={{ width: 100 }}>Бренды &#62;</div>
                            </Grid>
                            <Grid item xs={11} md={11} lg={1} onClick={() => {onClearAndRemoveSearchInput(); onPopupSearchInput()}}>
                                {showSkeletonSearchResult ?
                                    <Stack spacing={1}>
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                    </Stack>
                                    : <>
                                        {searchResult && searchResult.brends && searchResult.brends.length ? searchResult.brends.map((item: any, index: number) => (
                                            <div >
                                                <Link style={{ cursor: "pointer" }} to={"/catalog/filter/brand-in-" + item.id} key={index}>{item.name}</Link>
                                            </div>
                                        )) : null
                                        }
                                    </>}
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} style={{ marginRight: 5, fontWeight: "bold" }}>
                                <div style={{ width: 100 }}>Товары &#62;</div>
                            </Grid>
                            <Grid item xs={11} md={11} lg={5} onClick={() => {onClearAndRemoveSearchInput(); onPopupSearchInput()}}>
                                {showSkeletonSearchResult ?
                                    <Stack spacing={1}>
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                        <Skeleton variant="rectangular" width="100%" height={25} />
                                    </Stack>
                                    : <>
                                        {searchResult && searchResult.rows && searchResult.rows.length ? searchResult.rows.map((item: any, index: number) => (
                                            <>
                                                {index < 17 ?
                                                    <div key={index} style={{ display: "flex", margin: "auto", cursor: "pointer" }}>
                                                        <img src={item.img.small[0]} style={{ width: 20, height: 20 }} alt='' />
                                                        <div>
                                                            <Link style={{ cursor: "pointer" }} to={"/catalog/" + item.code} key={index}><span onClick={() => {onClearAndRemoveSearchInput(); onPopupSearchInput()}}>{item.name}</span></Link>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </>
                                        )) : null
                                        }
                                    </>}
                            </Grid>
                        </Grid>
                    </Box>
                    <IconButton onClick={onPopupSearchInput} sx={{ p: '10px', float: "right" }} aria-label="delete">
                        <ClearIcon style={{ color: '#b17882' }} />
                    </IconButton>
                </div>
            </>
        );
    }
}


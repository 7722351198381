import {RootStore} from '../index';
import {action, makeAutoObservable} from "mobx"
import Request1cModel from "../../model/Request1cModel";

export class PageCooperationStore {
    private rootStore: RootStore | undefined;

    public itemPhone = {
        code: 'phone',
        type: "text",
        title: 'Телефон',
        tooltip: 'Телефон',
        error: '',
        value: '',
        server: true,
        isMask: true,
        mask: '+375 (99) 999-99-99'
    };
    options = {
        Client: [],
        DeliveryType: [],
        PaymentType: [],
        Dates: [],
        Points: [],
    }
    public city: any = ''
    public validationForSendButton: boolean = false
    public unpAndUrlicoValidation: boolean = true
    public flag: boolean = false
    public arrOfAllValue: any = []
    public inputs = {

        contactFIO: {
            code: 'contactFIO',
            type: "text",
            title: 'ФИО*',
            tooltip: 'Иванов Иван Иванович',
            error: '',
            value: '',
            server: true,
        },

        email:{
            code: 'email',
            type: "email",
            title: 'E-mail*',
            tooltip: 'ivanon@gmail.com',
            error: '',
            value: '',
            server: true,
        },

        phone:{
            code: 'phone',
            type: "text",
            title: 'Телефон*',
            tooltip: '+375 (99) 999-99-99',
            error: '',
            value: '',
            server: true,
            isMask: true,
            mask: '+375 (99) 999-99-99'
        },

        address:{
            code: 'address',
            type: "text",
            title: 'Улица* (проспект, переулок и т.п)',
            tooltip: 'пр. Победителей ',
            error: '',
            value: '',
            server: true,
        },
        houseNumber:{
            code: 'houseNumber',
            type: "text",
            title: 'Дом*',
            tooltip: '1',
            error: '',
            value: '',
            server: true,
        },
        buildingNumber:{
            code: 'buildingNumber',
            type: "text2",
            title: 'Корпус',
            tooltip: '1',
            error: '',
            value: '',
            server: true,
        },

        apartmentNumber:{
            code: 'apartmentNumber',
            type: "text2",
            title: 'Квартира',
            tooltip: '1',
            error: '',
            value: '',
            server: true,
        },
        cityId: {
            code: 'cityId',
            type: "text",
            title: 'Город*',
            tooltip: 'Город',
            option: [],
            error: '',
            value: '',
            server: true,
        },
        fullNameOrg:{
            code: 'fullNameOrg',
            type: "text3",
            title: 'Юридическое лицо*',
            tooltip: 'ООО "Ваша организация"',
            error: '',
            value: '',
            server: true,

        },
        unp:{
            code: 'unp',
            type: "text3",
            title: 'УНП*',
            tooltip: '123456789',
            error: '',
            value: '',
            server: true,

        },
        isProcessed:{
            code: 'isProcessed',
            type: "checkbox",
            title: 'Я согласен на обработку персональных данных',
            tooltip: 'Я согласен на персональных данных',
            error: '',
            value: '',
            checked: false,
            server: true,
        },
        org_type:{
            code: 'org_type',
            type: "radio",
            title: 'Тип пользователя',
            tooltip: 'Тип пользователя',
            error: '',
            value: 'urlico',
            server: true,
        },
        password:{
            code: 'password',
            type: "password",
            title: 'Пароль*',
            tooltip: 'Пароль',
            error: '',
            value: '',
            server: true,
        },
        password2:{
            code: 'password2',
            type: "password",
            title: 'Повторите пароль*',
            tooltip: 'Повторите пароль',
            error: '',
            value: '',
        },
        beautyID:{
            code: 'beautyID',
            type: "beautyID",
            title: 'Beauty ID e-academie.ru',
            tooltip: '123456789888',
            error: '',
            value: '',
            server: true,
        },
        str:{
            code: 'str',
            type: "text2",
            title: 'Строение',
            tooltip: '1',
            error: '',
            value: '',
            server: true,
        },

    };

    public error: string = '';
    public redirect: boolean = false;
    public showUNP: boolean = true;

    beautyIdMessageShow = false

    showMessageAboutCooperationExist = false

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    init() {
        if (window.location.host === "localhost:3000") {

        }

    }



    @action
    onSubmitForm(e: { preventDefault: () => void; }) {
        e.preventDefault();
        let data = [];
        // @ts-ignore
        this.inputs.cityId = this.rootStore?.pageDeliveryAddressStore.inputs.cityId
        //Todo раскоментировать после запуска
        // if (!window.localStorage.getItem("beautyId") && this.inputs.beautyID.value.length === 0){
        //     this.beautyIdMessageShow = true
        //     return false
        // }
        //Todo раскоментировать после запуска


        for (let code in this.inputs) {
            // @ts-ignore
            let item=this.inputs[code];
            // @ts-ignore
            if (item.server) {
                data.push(item);
            }
        }

        let dt: any = {};
        data.map((item: any) => {
            dt[item.code] = item.value;
            return item;
        });

        let id = this.checkCity(true);

        if (this.validate() && this.validateUnp() && id ) {
            dt['cityId'] = id;
            dt['type'] = 'clientReg';
            dt['isProcessed'] = false;
            dt['siteId'] = 4;

            Request1cModel.createRegAll(
                dt,
                this.setSubmit,
                this.setError
            );
        }

        return false;
    }

    @action
    setSubmit = (result: any) => {
        this.error = '';
        // @ts-ignore
        this.rootStore.appStore.setPopupThankYouApplication(true);
    }



    @action
    onChangeInputCity = (e: { target: { value: string; }; }, item: any) => {

        item.value = e.target.value;
        if (item.value.length > 2) {
            if (!this.checkCity()) {
                this.getCity(item.value);
            }else{
                this.inputs.cityId.error='';
            }
        } else {
            item.option = [];
        }
    }
    @action
    onChangeCity= (item: any) => {
        this.inputs.cityId.value = item
    }
    @action getCity(q: string) {
        Request1cModel.getCity(q, this.setOptionCity, this.setError)
    }

    @action
    checkCity(isMessage = false) {
        let result = '';
        let val = this.inputs.cityId.value;
        // @ts-ignore
        let data = this.inputs.cityId.option.filter(item => item.name === val);
        if (!data.length) {
            if(isMessage){
                this.inputs.cityId.error = 'Выберите город из списка!';
            }
            result = '';
        } else {
            // @ts-ignore
            result = data[0].id;
        }
        return result;
    }

    @action setOptionCity = (result: any) => {

        // @ts-ignore
        this.inputs.cityId.option = result.data
    }
    @action
    onChangeInput = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;

        if (item.code === "password" || item.code === "password2"){
            this.validate()
        }
        if (item.code === "unp"){
            this.validateUnp()
        }
        if (item.code === "beautyID"){
            this.validateBeautyID()
        }

    }

    @action
    onChangeRadio = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;
        // this.unpAndUrlicoValidation = true
        for (let code in this.inputs) {
            // @ts-ignore
            let item=this.inputs[code];
            if ( item.code === "org_type" && item.value === "urlico"){
                this.unpAndUrlicoValidation = true
            }else if (item.code === "org_type" && item.value === "fizlico"){
                this.unpAndUrlicoValidation = false
                this.inputs.unp.value = ""
                this.inputs.fullNameOrg.value = ""
            }
        }

    }


    @action
    onChangePhone = (e: { target: { value: string; }; }, item: any) => {
        item.value = e.target.value;
        // this.checkValidate()
    }

    @action
    onChangeInputChecked = (checked: boolean, type: string, item: any) => {

        // @ts-ignore
        if(checked){
            item.checked = false;
        }else {
            item.checked = true;
        }

        for (let code in this.inputs) {
            // @ts-ignore
            let item=this.inputs[code];

            if ( item.code === "isProcessed" && item.checked === true){
                item.value="true"
                this.validationForSendButton = true
                // this.checkValidate()
            }else if (item.code === "isProcessed" && item.checked === false){
                item.value=""
                this.validationForSendButton = false
                // this.checkValidate()
            }

        }


    }

    @action
    validate() {
        let result = true;
        // PASSWORD
        let i1 = this.inputs.password;
        let i2 = this.inputs.password2;
        // let i3 = this.inputs.unp
        if (i1 !== undefined && i2 !== undefined) {
            if (i1.value !== i2.value) {
                i2.error = 'Неверное потверждение пароля!';
                result = false;
            } else if(i1.value === i2.value){
                i2.error = '';
            }
        }

        // if (i3 !== undefined && this.unpAndUrlicoValidation ) {
        //     if (i3.value.length !== 9) {
        //         i3.error = 'Длина УНП должна быть 9 символов';
        //         result = false;
        //     } else {
        //         i3.error = '';
        //     }
        // }
        if (i1.value.length < 8 ) {
            i1.error = 'Длина пароля должна быть больше 7';
            result = false;
        }
        if ( i1.value.length > 12) {
            i1.error = 'Длина пароля должна быть меньше 13';
            result = false;
        }

        if (/^[^A-ZА-ЯЁ]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы одну заглавную букву';
            result = false;
        }
        if (/^[^~!@#$%^&*()_+]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы один спец символ ~ ! @ # $ % ^ & * ( ) _ +';
            result = false;
        }
        if (/^[^1234567890]*$/.test(i1.value)){
            i1.error = 'Пароль должен содержать хотябы одну цифру';
            result = false;
        }
        if (i1.value.length > 8 && i1.value.length < 12 && !/^[^A-ZА-ЯЁ]*$/.test(i1.value) && !/^[^~!@#$%^&*()_+]*$/.test(i1.value) && !/^[^1234567890]*$/.test(i1.value)){
            i1.error = '';
            result = true;
        }

        // if (i1.value.length < 6 || i1.value.length > 12) {
        //     i1.error = 'Длина пароля от 6 до 12 символов';
        //     result = false;
        // }else{
        //     i1.error = '';
        // }
        return result;
    }
    // @action
    // validateBeautyID(bool:boolean) {
    //     let beautyId = this.inputs.beautyID.value
    //     if (!bool){
    //         this.beautyIdMessageShow = true
    //         return false
    //     }
    // }

    @action
    clickAfterValidateBeautyID() {
      let beautyId = this.inputs.beautyID.value
        if (beautyId.length === 0){
            this.beautyIdMessageShow = true
        }
    }
    @action
    closeModalValidateBeautyID() {
        this.beautyIdMessageShow = false
        window.localStorage.setItem("beautyId", "true")

    }

    @action
    validateUnp() {
        let result = true;
        // UNP
        let i3 = this.inputs.unp
        if (i3 !== undefined && this.unpAndUrlicoValidation ) {
            if (i3.value.length !== 9) {
                i3.error = 'Длина УНП должна быть 9 символов';
                result = false;
            } else {
                i3.error = '';
            }
        }
        return result;
    }
    @action
    validateBeautyID() {
        let result = true;
        // beautyID
        let i4 = this.inputs.beautyID
        if (i4 !== undefined ) {
            if (i4.value.length > 12) {
                i4.error = 'Длина BeautyID должна быть не больше 12 символов';
                result = false;
            }else if (/[a-zа-яё]/i.test(i4.value)) {
                i4.error = 'BeautyID не должен содержать букв';
                result = false;
            }
            else {
                i4.error = '';
            }
        }
        return result;
    }

    @action
    checkValidate() {
        this.arrOfAllValue =[]
        this.validationForSendButton = true
        for (let code in this.inputs) {
            // @ts-ignore
            let item=this.inputs[code];
            if (item.value === ""){
                this.validationForSendButton = false
            }
        }
    }

    @action
    setError = (error: any) => {
        this.showMessageAboutCooperationExist = false
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message === "Заявка с таким email существует"
        ) {
            this.showMessageAboutCooperationExist = true
        }


        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
        ) {
            this.error = String(error.response.data.message);
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            Array.isArray(error.response.data)
        ) {
            let el = {
                error: ''
            };
            error.response.data.map((item: any) => {
                // @ts-ignore
                el = this.inputs[item.code];
                if (el !== undefined) {
                    el.error = item.error;
                }
                return item;
            });
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            !Array.isArray(error.response.data)
        ) {
            return String(error.response.data);
        }else{
            this.error = String(error.response.data.message);
        }

    }

}



import { RootStore } from "../index";
import { action, makeAutoObservable } from "mobx";
import BlockPropertyModel from "../../model/BlockPropertyModel";

export class ComponentFilterCatalogStore {
  private rootStore: RootStore | undefined;
  private separator = "+";
  letter = [];
  brend = [];
  letterChecked: string[] = [];
  letterRu: string[] = [];
  listFilterCategory = [];
  listFilters = [];
  listFilters2 = [];
  listFiltersPallet = [];
  listPalletImg = [];
  listFiltersHairType = [];
  listFiltersSubLine = [];
  listFiltersLineGamma = [];
  listFiltersBrand = [];

  showFilterMobile = false;
  showFilterDestop = false;
  filterActive: any = {};
  filterActiveSub: any = {};
  arBrand = [];
  arBrandForMainPageProfessionnel = false;
  arBrandForMainPageRedken = false;
  arBrandForMainPageMatrix = false;
  arBrandForMainPageKerastase = false;
  arBrandShow = [];
  arBrandChecked: number[] = [];
  arFilter: any = {};
  onChecks: number[] = [];
  public error: string = "";
  clientId: number = 0;
  isOpenSubMenuSubCatalog: boolean = false;
  isSale: any;
  showPallet: boolean = false;
  showItemOrPallet: boolean = false;

  pagination = {
    countPage: 1,
    countRow: 1,
    currentPage: 1,
  };

  url1: string = "";
  url2: string = "";
  url5: string = "";
  selectedFilter: any = [];

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  init(filter: string) {
    if (filter) {
      let arFilter = filter?.split(this.separator);
      if (arFilter.length) {
        for (let f of arFilter) {
          if (f.indexOf("-is-") >= 0) {
            let arProp = f.split("-is-");
            this.arFilter[arProp[0]] = arProp[1];
            this.filterActive["OurOffers"] = true;
            switch (arProp[0]) {
              case "isNovelty":
                this.onChecks.push(1);
                break;
              case "isTopBeautyHouse":
                this.onChecks.push(2);
                break;
              case "isSellout":
                this.onChecks.push(3);
                break;
              case "isIncludeLoyaltyProgram":
                this.onChecks.push(4);
                break;
            }
          } else if (f.indexOf("-in-")) {
            let arProp = f.split("-in-");
            let spros = arProp[1].split(",");
            let props = [];
            for (let key in spros) {
              props.push(parseInt(spros[key]));
              this.onChecks.push(parseInt(spros[key]));
            }
            this.filterActive[arProp[0]] = true;
            this.arFilter[arProp[0]] = props;
          } else {
            console.log("");
          }
        }
      }
    }
    // @ts-ignore
    this.clientId = this.rootStore.mainStore.getClientId();
    // @ts-ignore
    this.reload(false);
  }

  reload(init: boolean) {
    if (init) {
      this.pagination.currentPage = 1;
    } else {
      // @ts-ignore
      this.pagination.currentPage =
        this.rootStore?.pageCatalogStore.pagination.currentPage;
    }

    if (this.rootStore?.mainStore.isAuth) {
      BlockPropertyModel.getFilter(
        {
          json: {
            clientId: this.clientId,
            limit: 18,
            filter: this.getFilter(),
            sort: this.setSort(),
          },
          page: this.pagination.currentPage,
        },
        this.setCatalogFilter,
        this.setErrorFilter
      );

      // BlockPropertyModel.getBrand(
      //   {
      //     json: {
      //       clientId: this.clientId,
      //       filter: this.getFilter(),
      //     },
      //   },
      //   this.setCatalog,
      //   this.setError
      // );
    }
  }

  reload2(init: boolean) {
    this.arFilter = {};
    if (this.rootStore?.mainStore.isAuth) {
      BlockPropertyModel.getBrand(
        {
          json: {
            clientId: this.clientId,
            filter: this.getFilter(),
          },
        },
        this.setCatalog,
        this.setError
      );
    }
  }
  private setSort() {
    return {
      orderModel: "",
      orderField: "name",
      orderBy: "ASC",
    };
  }

  getPallet() {
    BlockPropertyModel.getPalletImg(this.setError, this.setPalletImg);
  }
  getFilter() {
    let a = this.rootStore?.pageCatalogStore.search

    if (a && a.length > 1 && Array.isArray(this.rootStore?.pageCatalogStore.arItems)
        && this.rootStore?.pageCatalogStore.arItems[0]
        // @ts-ignore
        && this.rootStore?.pageCatalogStore.arItems[0].props){
      // @ts-ignore
      let brand = this.rootStore?.pageCatalogStore.arItems[0].props.brand
      // @ts-ignore
      let beautyHouseCategory = this.rootStore?.pageCatalogStore.arItems[0].props.beautyHouseCategory
      return {
        props: {"brand":[brand], "beautyHouseCategory":[beautyHouseCategory]},
      }
    }
    return {
      props: this.arFilter,
    };
  }

  @action
  setPalletImg = (result: any) => {
    this.listPalletImg = result.data;

    // @ts-ignore
    if (  this.listFiltersPallet && this.listFiltersPallet?.enum && this.listFiltersPallet?.enum.length
    ) {
      // @ts-ignore
      for (let i = 0; i < this.listFiltersPallet.enum.length; i++) {
        for (let a = 0; a < this.listPalletImg.length; a++) {
          // @ts-ignore
          if (this.listFiltersPallet.enum[i].id === this.listPalletImg[a].id) {
            // @ts-ignore
            this.listFiltersPallet.enum[i]["src"] = this.listPalletImg[a].src;
          }
        }
      }
    }
  };

  @action
  setCatalog = (result: any) => {
    this.arBrand = result.data.enum;

    for (let i = 0; i < this.arBrand.length; i++) {
      // @ts-ignore
      if (this.arBrand[i].value === "L'Oreal Professionnel") {
        this.arBrandForMainPageProfessionnel = true;
      }
      // @ts-ignore
      if (this.arBrand[i].value === "Redken") {
        this.arBrandForMainPageRedken = true;
      }
      // @ts-ignore
      if (this.arBrand[i].value === "Matrix") {
        this.arBrandForMainPageMatrix = true;
      }
      // @ts-ignore
      if (this.arBrand[i].value === "Kerastase") {
        this.arBrandForMainPageKerastase = true;
      }
    }
  };

  @action
  setError = (error: any) => {
    console.log(error);
    // this.error = error.response.data.massage;
  };

  @action
  setCatalogFilter = (result: any) => {
    let obj = result.data;

    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].code === "pallet") {
        this.listFiltersPallet = result.data[i];
      }
    }

    this.listFilters = obj;
    for (let key in this.listFilters) {
      if (
        this.listFilters[key]["code"] !== "pallet" &&
        this.listFilters[key]["code"] !== "itemType"
        // && this.listFilters[key]["code"] !== "subGamma"
      ) {
        delete this.listFilters[key];
      }
    }

    this.listFiltersBrand = obj;
    for (let key in this.listFiltersBrand) {
      if (this.listFiltersBrand[key]["code"] !== "brand") {
        delete this.listFiltersBrand[key];
      }
    }


    this.listFilterCategory = obj;
    for (let key in this.listFilterCategory) {
      if (this.listFilterCategory[key]["code"] !== "beautyHouseCategory") {
        delete this.listFilterCategory[key];
      }
    }
    this.listFiltersHairType = obj;
    for (let key in this.listFiltersHairType) {
      if (
        this.listFiltersHairType[key]["code"] !== "coloring" &&
        this.listFiltersHairType[key]["code"] !== "dyesType" &&
        this.listFiltersHairType[key]["code"] !== "hairType" &&
        this.listFiltersHairType[key]["code"] !== "additionalService" &&
        this.listFiltersHairType[key]["code"] !== "fixingDegree" &&
        this.listFiltersHairType[key]["code"] !== "technicalFormat"
      ) {
        delete this.listFiltersHairType[key];
      }
    }

    this.listFiltersSubLine = obj;
    for (let key in this.listFiltersSubLine) {
      if (this.listFiltersSubLine[key]["code"] !== "subLine") {
        delete this.listFiltersSubLine[key];
      }
    }
    this.listFiltersLineGamma = obj;
    for (let key in this.listFiltersLineGamma) {
      if (this.listFiltersLineGamma[key]["code"] !== "seriesLineGamma") {
        delete this.listFiltersLineGamma[key];
      }
    }

    // this.listFiltersBrand = obj;
    console.log('3333333333')
    console.log(obj)
    // for (let key in this.listFiltersBrand) {
    //   if (this.listFiltersBrand[key]["code"] !== "brand") {
    //     delete this.listFiltersBrand[key];
    //   }
    // }

    let filter = this.getFilter();

    if (
      !filter.props.hasOwnProperty("seriesLineGamma") &&
      !filter.props.hasOwnProperty("subLine")
    ) {
      for (let key in this.listFilters) {
        let dt = this.listFilters[key];
        // @ts-ignore
        if (dt?.code === "pallet") {
            delete this.listFilters[key];
        }
      }
    }
  };

  @action
  setErrorFilter = (error: any) => {
    console.log(error);
  };

  @action
  onLetterChecked = (l: string) => {
    if (this.letterChecked.includes(l)) {
      this.letterChecked.splice(this.letterChecked.indexOf(l), 1);
    } else {
      this.letterChecked.push(l);
    }
    this.brandShow();
  };

  brandShow() {
    this.arBrandShow = [];
    this.letterChecked.map((letter: string) => {
      this.arBrand.map((item) => {
        // @ts-ignore
        if (item.name.substr(0, 1).toUpperCase() === letter) {
          this.arBrandShow.push(item);
        }
        return item;
      });
      return letter;
    });
  }

  @action
  onBrandChecked = (id: number, code: string) => {
    this.rootStore?.pageCatalogStore.updateCurrentPage();
    this.isSale = code;
    if (
      code !== "isNovelty" &&
      code !== "isTopBeautyHouse" &&
      code !== "isSellout" &&
      code !== "isIncludeLoyaltyProgram"
    ) {
      if (this.arFilter[code]) {
        let arData = this.arFilter[code];
        if (arData.includes(id)) {
          arData.splice(arData.indexOf(id), 1);
        } else {
          arData.push(id);
        }
        if (arData.length) {
          this.arFilter[code] = arData;
        } else {
          delete this.arFilter[code];
        }
      } else {
        this.arFilter[code] = [];
        let arData = this.arFilter[code];
        arData.push(id);
        this.arFilter[code] = arData;
      }
    }

    // для новинак или топ -----------
    if (
      code === "isNovelty" ||
      code === "isTopBeautyHouse" ||
      code === "isSellout" ||
      code === "isIncludeLoyaltyProgram"
    ) {
      if (this.arFilter[code]) {
        let arData = this.arFilter[code];
        if (arData) {
          arData = false;
        } else {
          arData = true;
        }
        if (arData) {
          this.arFilter[code] = arData;
        } else {
          delete this.arFilter[code];
        }
      } else {
        this.arFilter[code] = true;
      }
    }

    // --------------------------------------
    if (this.onChecks.includes(id)) {
      this.onChecks.splice(this.onChecks.indexOf(id), 1);
    } else {
      this.onChecks.push(id);
    }
    this.setUrlPage(id, code);
    // @ts-ignore
    this.rootStore?.pageCatalogStore.reload(1);

    // обновляем занчения фильтра!
    this.reload(false);

    if (this.onChecks.includes(id)) {
      return;
    }
  };

  @action
  onBrandChecked3 = (id: number, code: string) => {
    this.rootStore?.appStore.setOpenMenuCatalog(true);
    this.setUrlPage(id, code);
  };
  @action
  onBrandChecked4 = (id: number, code: string) => {
    // @ts-ignore
    window.history.pushState(null, null, "/catalog");
    this.setUrlPage(id, code);
  };

  @action
  onPageBrandChecked = (id: number, code: string) => {
    this.rootStore?.appStore.closeMenuAction();
    this.filterActiveSub = this.rootStore?.appStore.activeFilterId;
    this.arFilter = {};
    this.arFilter[code] = [];
    this.arFilter[code] = [id];
    this.onChecks = [];
    this.onChecks.push(id);
    this.onChecks.push(this.filterActiveSub);

    this.filterActive[code] = true;

    if (this.rootStore?.appStore.geOpenMenuCatalogt()) {
      // @ts-ignore
      this.rootStore?.appStore.setOpenMenuCatalog();
    }

    // @ts-ignore
    this.rootStore?.pageCatalogStore.reload(1);
    this.rootStore?.appStore.closeMenuAction();
  };
  onPageBrandCheckedMobile = (id: number, code: string) => {
    if (window.innerWidth > 1024) {
      window.location.reload();
    }
    this.onBrandChecked(id, code);
    this.arFilter = {};
    this.arFilter[code] = [];
    this.arFilter[code] = [id];
    this.onChecks = [];
    this.onChecks.push(id);

    this.filterActive[code] = true;

    this.setOpenSubMenuSubCatalog();
  };

  @action
  onPageMainClickSlider = (props: any) => {
    this.arFilter = {};

    this.onChecks = [];
    if (props["brand"]) {
      this.arFilter["brand"] = [];
      this.arFilter["brand"] = [props["brand"]];
      this.onChecks.push(props["brand"]);
      this.filterActive["brand"] = true;
    }
    if (props["seriesLineGamma"]) {
      this.arFilter["seriesLineGamma"] = [];
      this.arFilter["seriesLineGamma"] = [props["seriesLineGamma"]];
      this.onChecks.push(props["seriesLineGamma"]);
      this.filterActive["seriesLineGamma"] = true;
    }
    if (props["pallet"]) {
      this.arFilter["pallet"] = [];
      this.arFilter["pallet"] = [props["pallet"]];
      this.onChecks.push(props["pallet"]);
      this.filterActive["pallet"] = true;
    }
    if (props["subLine"]) {
      this.arFilter["subLine"] = [];
      this.arFilter["subLine"] = [props["subLine"]];
      this.onChecks.push(props["subLine"]);
      this.filterActive["subLine"] = true;
    }

    if (this.rootStore?.appStore.geOpenMenuCatalogt()) {
      // @ts-ignore
      this.rootStore?.appStore.setOpenMenuCatalog();
    }
    this.rootStore?.mainStore.setUrl("/catalog");
    // @ts-ignore
    this.rootStore?.pageCatalogStore.reload(1);
  };

  @action
  onPageBrandCheckedFromProduct = (
    idBrand: number,
    codeBrand: string,
    idCategory: number,
    codeCategory: string
  ) => {
    this.arFilter = {};
    this.arFilter[codeBrand] = [];
    this.arFilter[codeBrand] = [idBrand];
    this.arFilter[codeCategory] = [];
    this.arFilter[codeCategory] = [idCategory];
    this.onChecks = [];
    this.onChecks.push(idBrand);
    this.onChecks.push(idCategory);

    this.filterActive[codeBrand] = true;
    this.filterActive[codeCategory] = true;

    if (this.rootStore?.appStore.geOpenMenuCatalogt()) {
      // @ts-ignore
      this.rootStore?.appStore.setOpenMenuCatalog();
    }
    this.rootStore?.mainStore.setUrl("/catalog");
  };

  resetFilter = () => {
    // @ts-ignore
    window.history.pushState(null, null, "/catalog");
    this.arFilter = {};
    this.onChecks = [];
    // @ts-ignore
    this.rootStore?.pageCatalogStore.init();
  };

  resetFilterAfterSearch = () => {
    this.arFilter = {};
    this.onChecks = [];
  }



  removePageFromUrl = () => {
    console.log(this.url1);
  };

  @action
  onFilterChecked = (code: string) => {
    // @ts-ignore
    if (this.filterActive[code] === true) {
      // @ts-ignore
      this.filterActive[code] = false;
    } else {
      // @ts-ignore
      this.filterActive[code] = true;
    }
  };

  setUrlPage3(id: number, code: string) {

    let mainUrl = this.rootStore?.componentUrlStore.mainUrl;
    for (let key in mainUrl) {
      if (key === code) {
        // @ts-ignore
        mainUrl[key].value = true;
        // @ts-ignore
        mainUrl[key].codeId = id;
      }
    }
    this.arBrandChecked.push(id);
    let arS15text = [];
    let urlTest = this.getBaseUrlCatalog();
    for (let key in mainUrl) {
      // @ts-ignore
      if (mainUrl[key].value === true) {
        // @ts-ignore
        arS15text.push(mainUrl[key].text + "_" + mainUrl[key].codeId + "_");
      }
    }
    urlTest += "filter/" + arS15text;

    this.url5 = urlTest.replace(/[\s.,%]/g, "");
    // @ts-ignore
    window.history.pushState(null, null, urlTest.replace(/[\s.,%]/g, ""));
    // @ts-ignore
    window.location.reload(urlTest);
  }

  setUrlPage(id: number, code: string) {
    let arS = [];
    for (let code in this.arFilter) {
      let dt = this.arFilter[code];
      if (Array.isArray(dt)) {
        arS.push(code + "-in-" + dt.join(","));
      } else {
        arS.push(code + "-is-" + dt);
      }
    }
    let url = this.getBaseUrlCatalog();

    if (arS.length) {
      url += "filter/" + arS.join(this.separator);
    }
    if (window.location.search.length) {
      let page = window.location.search;
      // @ts-ignore
      url += page;
    }
    // @ts-ignore
    window.history.pushState(null, null, url);
  }

  getBaseUrlCatalog() {
    let s = "/catalog/";
    return s;
  }
  removeUrl = () => {
    console.log();
  };

  setUrlPage1(id: number, code: string) {
    let mainUrl = this.rootStore?.componentUrlStore.mainUrl;
    for (let key in mainUrl) {
      // @ts-ignore
      mainUrl[key].value = false;
      // @ts-ignore
      mainUrl[key].codeId = 0;
    }
    for (let key in mainUrl) {
      if (key === code) {
        // @ts-ignore
        mainUrl[key].value = true;
        // @ts-ignore
        mainUrl[key].codeId = id;
      }
    }
    this.arBrandChecked.push(id);
    let arS15text = [];

    let urlTest = this.getBaseUrlCatalog();
    // @ts-ignore
    for (let key in mainUrl) {
      // @ts-ignore
      if (mainUrl[key].value === true) {
        // @ts-ignore
        arS15text.push(mainUrl[key].text + "-in-" + mainUrl[key].codeId);
      }
    }
    urlTest += "filter/" + arS15text;
    this.url1 = urlTest.replace(/[\s.,%]/g, "") + '?1';
    console.log(this.url1)
    // @ts-ignore
    window.history.pushState(null, null, urlTest.replace(/[\s.,%]/g, "") + '?1');
  }

  setUrlPageMouseEnter(id: number, code: string) {
    this.url1 = window.location.pathname + "+" + code + "-in-" + id + "?1";
  }

  @action
  showFilter = () => {
    this.showFilterMobile = true;
    this.showFilterDestop = true;
  };

  @action
  closeFilter = () => {
    this.showFilterMobile = false;
    this.showFilterDestop = false;
  };
  @action
  setOpenSubMenuSubCatalog = () => {
    this.isOpenSubMenuSubCatalog = !this.isOpenSubMenuSubCatalog;
  };

  @action
  showPalletOrImg = (val: any) => {
    this.showPallet = val;
  };
  @action
  showItemsOrPalletAction = (val: any) => {
    this.showItemOrPallet = val;
  };
}

import React from 'react';
import {RenderGoods} from "../components/catalog/RenderGoods";
import {AnyQuestions} from "../components/block/AnyQuestions";
import {withStore} from "../hocs";
import {observer} from "mobx-react";
import {SortGrid} from "../components/catalog/SortGrid";
import {FilterCatalog} from "../components/catalog/filter/FilterCatalog";
import {withRouter} from "react-router";
import {WIDTH} from "../const/root";
import ReactGA from "react-ga";
import {YOUR_TRACKING_ID} from "../stores/AppStore";
import {GoodsItem} from "../components/catalog/GoodsItem";
import {FilterItem} from "../components/catalog/filter/FilterItem";

// @ts-ignore
@withStore
@observer

class CatalogPage extends React.Component {

    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentDidMount() {
        // @ts-ignore
        const {store} = this.props;
        const {isPageBrend} = store?.pageCatalogStore;
        // @ts-ignore
        const code1 = this.props.match.params.code1;
        // @ts-ignore
        const code2 = this.props.match.params.code2;
        // @ts-ignore
        const filter = this.props.match.params.filter;
        if(!isPageBrend){
            store?.pageCatalogStore.init(String(code1), String(code2),filter);
        }
        let isAuth = store.mainStore.isAuth;
        if(!isAuth){
            store.appStore.setPopupAccessDenied(true)
        }
        this.setGA();
    }
    render() {

        // @ts-ignore
        const {store} = this.props;
        const {isShow,
            toggleFIeldset,
            arItems,
            pagination,
            onCurrentPage,
            setSortParams,
            orderField,
            selectSortValue,
            showSkeleton,
            arItemsSpecials,
        } = store?.pageCatalogStore;

        let {
            onFilterChecked,
            onBrandChecked,
            onChecks,
            listFiltersLineGamma,
            filterActive,
        } = store?.componentFilterCatalogStore;

        let viewDesktop = store.pageCatalogStore.viewCatalogClass
        let viewMobile = store.pageCatalogStore.viewCatalogClassMobile
        let view = viewDesktop

        let Width = window.innerWidth
        if (Width < WIDTH){view = viewMobile}

        const {countPage,currentPage}=pagination;
        let show = isShow;
        let selectValue = selectSortValue;

        return (
            <>
                <main className="page__main">

                    <div className="container">

                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"> Каталог</li>
                        </ul>
                        <h1 style={Width< WIDTH ? {marginBottom: -10}: {}} className="page__title">Каталог</h1>
                        <div className="page__main-grid">

                            <div className="page__main-column goods-catalog goods-catalog--main">

                                <div className="goods-catalog__sort">
                                    {Width < WIDTH ?  <FilterCatalog />: null}

                                    <div className="sort-items" >

                                        <button onClick={toggleFIeldset}   className="sort-items__toggle js--toggle-sorting"
                                                type="button"><span style={Width < WIDTH ? {
                                                    color: "#b17882",
                                                    fontWeight: 500,
                                                    fontSize: 16,
                                                    cursor: "pointer"
                                                }: {
                                                    color: "#b17882",
                                                    fontWeight: 700,
                                                    fontSize: 18,
                                                    cursor: "pointer"
                                                }}>Сортировка</span>

                                            {!show && <svg className="sort-items__icon" width="7" height="12">
                                                <use xlinkHref="images/sprite.svg#icon--arrow-right" />
                                            </svg>}
                                            {show &&  <svg className="sort-items__icon" width="7" height="12">
                                                <use xlinkHref="images/sprite.svg#icon--arrow-left" />
                                            </svg>}
                                        </button>
                                        <button className="single-product__add-cart button button--accent-dark"
                                                onClick={()=>window.scrollTo(0, 0)}
                                                style={Width > WIDTH ? {position: "fixed", width: 80, top: "90%", left: '90%', borderRadius: 35}
                                            :{position: "fixed", top: "90%", left: '75%', borderRadius: 35, width: 80, zIndex: 1} }>
                                            Вверх
                                        </button>
                                        {show &&
                                        <div style={{position: "absolute", background: "white", zIndex: "4", margin: "-130px -5px", border: "1px solid #f3e5e8", padding: 5}} className="filter-list-wrapper">

                                            <div className="filter-list-item-2" onClick={() => setSortParams('name')}>
                                                <input
                                                    // @ts-ignore
                                                    checked={selectValue === 'по наименованию' ?  'checked' : '' }
                                                    className="visually-hidden" type="radio" name="name"
                                                    id="name"  />
                                                <label className="sort-items__label radio-field"
                                                       htmlFor="sort-name">
                                                    по наименованию {orderField==='name'}
                                                </label>
                                            </div>
                                            <div className="filter-list-item-2" onClick={() => setSortParams('priceUp')}>
                                                <input
                                                    // @ts-ignore
                                                    checked={selectValue === 'по возрастанию цены' ?  'checked' : '' }
                                                    className="visually-hidden" type="radio" name="createdAt"
                                                    id="priceUp"  />
                                                <label className="sort-items__label radio-field"
                                                       htmlFor="price-up">
                                                    цена по возростанию {orderField==='priceUp'}
                                                </label>
                                            </div>
                                            <div className="filter-list-item-2" onClick={() => setSortParams('priceDown')}>
                                                <input
                                                    // @ts-ignore
                                                    checked={selectValue === 'по убыванию цены' ?  'checked' : '' }
                                                    className="visually-hidden" type="radio" name="createdAt"
                                                    id="createdAt" />
                                                <label className="sort-items__label radio-field"
                                                       htmlFor="price-down">
                                                    цена по убыванию {orderField==='priceDown'}
                                                </label>
                                            </div>
                                            <div className="filter-list-item-2" onClick={() => setSortParams('createdAt')}>
                                            <input
                                                // @ts-ignore
                                                checked={selectValue === 'по новизне' ?  'checked' : '' }
                                                className="visually-hidden" type="radio" name="createdAt"
                                               id="createdAt" />
                                            <label className="sort-items__label radio-field"
                                                   htmlFor="createdAt">
                                              по новизне {orderField==='createdAt' }
                                            </label>
                                            </div>

                                        </div>
                                        }
                                    </div>
                                    <SortGrid/>

                                </div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "auto",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    {listFiltersLineGamma.map((itemFilter: any, index: any) => (
                                        <>
                                            <FilterItem
                                                key={index}
                                                store = {store}
                                                itemFilter = {itemFilter}
                                                filterActive = {filterActive}
                                                onFilterChecked ={onFilterChecked}
                                                onChecks={onChecks}
                                                onBrandChecked={onBrandChecked}
                                                type={"catalog"}
                                            />

                                        </>
                                    ))}
                                </div>
                                <hr style={{opacity: 0.15, marginTop: 25}}/>

                                    {arItems.length > 0 ? <RenderGoods view={view}
                                                                       items={arItems}
                                                                       countPage={countPage}
                                                                       onCurrentPage={onCurrentPage}
                                                                       currentPage={currentPage}
                                                                       showSkeleton={showSkeleton }
                                        /> :
                                        null
                                    }
                                <hr style={{opacity: 0.15, marginTop: 25}}/>
                                <div className="container" style={{marginTop: 25}}>
                                    {arItemsSpecials && arItemsSpecials.length > 0 && arItemsSpecials.length <= 2  ?
                                        <div style={{width: "100%", borderBottom: "1px solid #e6ebef"}}>
                                            <div className="single-product__recommended" style={{borderTop: "none", marginTop: 0, paddingTop: 0}}>
                                                <h2 className="section__title">Бестселлеры</h2>
                                                <div style={{display: "flex", alignItems: "center", margin: "auto", justifyContent: "space-around"}}>
                                                    {arItemsSpecials.map((item: any, index: number) => (
                                                        <GoodsItem key={index} item={item} width={arItemsSpecials.length < 4 ? "1" : "450px"} type={"Бестселлеры"}/>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>: null }
                                </div>
                            </div>
                            {/* Фильтор */}
                            {Width > WIDTH ?   <FilterCatalog /> : null}

                        </div>
                    </div>
                    {/* Остались вопросы? */}
                    <AnyQuestions />
                </main>
            </>
        )
    }
}

// @ts-ignore
export default withRouter(CatalogPage);
import React from 'react';
import {BasketItem} from "./BasketItem";
import {withStore} from "../../hocs";
import {observer} from "mobx-react";
import { Link } from 'react-router-dom'
import {WIDTH} from "../../const/root";
import {Tooltip} from "@mui/material";

interface Props {
    item: {};
    key: any;
    width: string;
    type: string
}
// @ts-ignore
@withStore
@observer
export class GoodsItem extends React.Component<Props, {}> {
    componentDidMount() {

    }
    // @ts-ignore
    addDefaultSrc = ev => {
        ev.target.src = '/images/pic--goods-0.jpg'
    }

    render() {
        // @ts-ignore
        const {store} = this.props;
        const {addFavorite, arFavorite, deleteFavorite, activeMappingsCatalogListMobile, viewCatalogClassMobile} = store.pageCatalogStore;

        const userId = store.mainStore.id;
        const clientId = store.mainStore.clientId;
        const includeUserLoyaltyProgram = store.mainStore && store.mainStore.isIncludeLoyaltyProgram;

        let item: any = this.props.item;
        let width333: any = this.props.width;
        let type: string = this.props.type;

        const price = item.price ? parseFloat(item.price.final_price) : 0;
        const priceOld = item.price ? parseFloat(item.price.base_price) : 0;
        const isPriceOld = price !== priceOld;
        let index = this.props.key;

        let icon = false;
        let showMainPictureOrColorPallet= false

        for( let i= 0; i < arFavorite.length; i++){
            if (arFavorite[i].productId === item.id ){
                icon = true;
            }
        }

        const {propsNoFilter, img} = item;


        let dyesType = propsNoFilter && propsNoFilter.dyesType ? propsNoFilter.dyesType : '';
        let hairType = propsNoFilter && propsNoFilter.hairType ? propsNoFilter.hairType : '';   //Тип волос
        let itemType = '';           //Тип продукта

        const {
            cosmeticEffectName,
            productTextureName,
            subLine,
            forWhomName,
            fixingDegree,
            pallet,
            brand,
            producerCountryName,
            subGamma,
            seriesLineGamma,
            isTopBeautyHouse,
            vat,
            isNovelty,
        } = propsNoFilter || {};


        if (dyesType === undefined) { // Тип Красителя
            dyesType = '';
        }
        if (hairType === undefined) { // Тип волос
            hairType = '';
        }
        let Width = window.innerWidth;

        let location = window.location.pathname


        if ( location.includes('itemType') && location.includes('10851')){
            showMainPictureOrColorPallet=true
        }

        if ( location.includes('pallet') || location.includes('10851')){
            showMainPictureOrColorPallet=true
        }


        // let view = store.pageCatalogStore.viewCatalogClass

        let style = {}
        if (width333 === "450px"){
            style = {width: "100%",maxHeight: 550 }
        }

        let activeMappingsCatalogListMobile2 = activeMappingsCatalogListMobile
        let viewCatalogClassMobile2 = viewCatalogClassMobile

        if (window.location.pathname === "/" || window.location.search.includes("?p=") || type==="Бестселлеры"){
            activeMappingsCatalogListMobile2 = ""
            viewCatalogClassMobile2 = ""
        }

        return (
            <>
                <article className="goods--is-favorite goods goods-catalog__item" style={style} key={index + Date.now()} >
                    <div key={'goo-'+item.id + index} className="goods__thumbnail-wrapper  aspect-ratio" style={width333 === "450px" ? {width: "70%"} : {}}  >

                        {isTopBeautyHouse === true ?
                            <span className="goods__label goods__label--top">TOP</span>
                            : null}
                        {isNovelty === true ?
                            <span className="goods__label goods__label--top" style={{paddingLeft: "70%"}}>NEW</span>
                            : null}

                        {showMainPictureOrColorPallet && store.componentFilterCatalogStore.showPallet ?
                            <>
                                <Link to={'/catalog/'+item.code+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)} >
                                    <div className="aspect-ratio__inner" >
                                        <img className="goods__thumbnail"
                                             style={{borderRadius: '200px'}}
                                             onError={this.addDefaultSrc}
                                             src={img['tone'][0] !== undefined ? img['tone'][0]+ '?p=' + Math.round(Math.round(Math.random()*1000)/100): ""}
                                             alt={item.name+'-'+item.code}
                                        />
                                    </div>
                                </Link>
                            </>
                            :
                            <>

                                <Link to={'/catalog/'+item.code+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)} >
                                    <div className="aspect-ratio__inner" style={activeMappingsCatalogListMobile2 === "list" && Width < WIDTH  ? {marginTop: -65}: {}}>
                                        {includeUserLoyaltyProgram ?
                                            <Tooltip title="Товар учавствует в бонусной программе">
                                                <img src="/images/color_b.png"
                                                     width={Width < WIDTH ? "20px" : "30px"}
                                                     style={activeMappingsCatalogListMobile2 === "list" && Width < WIDTH ?
                                                         {mixBlendMode: 'multiply', position: "absolute", top: "50%", left:"70%"} :
                                                         {mixBlendMode: 'multiply', position: "absolute", top: "85%", left: "0%"}}
                                                         alt=''
                                                />
                                            </Tooltip>
                                            : null}
                                        <img className="goods__thumbnail"
                                             style={activeMappingsCatalogListMobile2 === "list" && Width < WIDTH  ? {maxHeight: "20%", maxWidth: "50%"} : {}}
                                             onError={this.addDefaultSrc}
                                             src={img && img['small'] && img['small'][0] !== undefined ? img['small'][0]+ '?p=' + Math.round(Math.round(Math.random()*1000)/100) : ""}
                                             alt={item.name+'-'+item.code}
                                        />
                                    </div>
                                </Link>

                                {activeMappingsCatalogListMobile2 === "list" && Width < WIDTH ? <>

                                    {Width < WIDTH ?  <div style={{marginTop: '-60px'}} className="box-price">

                                        <div style={Width<WIDTH ? {width: 150, paddingBottom: 10, }:{width: 250}} className="goods__price">
                                            <div  style={{paddingBottom: "-30px"}}>
                                                {isPriceOld ? <sup style={Width<WIDTH ? {fontSize:10, left: 50}:{fontSize:12, bottom: 30}} className="goods__price old-price">
                                                    {((priceOld * parseFloat(vat) /100) + priceOld).toFixed(2)}  BYN
                                                </sup> : null}
                                            </div>
                                            <div>
                                                {price ? ((price * parseFloat(vat) /100) + price).toFixed(2) + ' BYN' : null}
                                            </div>
                                        </div>
                                    </div> :  <div className="box-price">

                                        <div style={{width: 250}} className="goods__price">
                                        <span>
                                            {price ? ((price * parseFloat(vat) /100) + price).toFixed(2) + ' BYN' : null}
                                        </span>
                                            {isPriceOld ? <span  style={{paddingLeft: "20px"}} className="goods__price old-price">
                                                {((priceOld * parseFloat(vat) /100) + priceOld).toFixed(2)}  BYN
                                        </span>: null}
                                        </div>
                                    </div> }
                                </> : null}



                            </>
                        }


                        {
                            icon ?
                                <span
                                    onClick={ () => deleteFavorite(item.id, userId, clientId)}

                                    className="goods__favorite js--favorite-toggle"/>

                                : <span style={{backgroundImage: `url("../../images/bg--favorite-accent.png")`}}  onClick={ () => addFavorite(item.id, userId, clientId)} className="goods__favorite js--favorite-toggle"/>
                        }


                    </div>
                    <div key={item.id + '000' + index} className="goods__content" >
                        <div style={width333 === "450px" ? {height: 130, marginBottom: 0} : { marginBottom: 0}} className="goods__content-inner" >
                            <h3 className="goods__title">
                                <Link to={'/catalog/'+item.code+ '?p=' + Math.round(Math.round(Math.random()*1000)/100)}>{item.name}</Link>
                            </h3>
                        </div>

                        <table key={'111go-'+item.id + index} className="goods__options options-table">

                            {brand && brand !== '' ?
                                <tr key='prop-20'>
                                    <td><span className="options-table__key">Бренд</span></td>
                                    <td>{brand}</td>
                                </tr>
                                : null}

                            {seriesLineGamma && seriesLineGamma !== '' ?
                                <tr key='prop-01'>
                                    <td><span className="options-table__key">Линейка бренда</span></td>
                                    <td>{seriesLineGamma}</td>
                                </tr>
                                : null}

                            {subLine && subLine !== ''?
                                <tr key='prop-02'>
                                    <td><span className="options-table__key">Подлиния</span>
                                    </td>
                                    <td>{subLine}</td>
                                </tr>
                                : null}

                            {itemType && itemType !== ''?
                                <tr key='prop-07'>
                                    <td><span className="options-table__key">Тип продукта</span>
                                    </td>
                                    <td>{itemType}</td>
                                </tr>
                                : null}

                            {forWhomName && forWhomName !== ''?
                                <tr key='prop-03'>
                                    <td><span className="options-table__key">Для кого</span>
                                    </td>
                                    <td>{forWhomName}</td>
                                </tr>
                                : null}

                            {hairType && hairType !== ''?
                                <tr key='prop-09'>
                                    <td><span className="options-table__key">Тип волос</span>
                                    </td>
                                    <td>{hairType}</td>
                                </tr>
                                : null}

                            {fixingDegree && fixingDegree !== ''?
                                <tr key='prop-04'>
                                    <td><span className="options-table__key">Степинь фиксации</span>
                                    </td>
                                    <td>{fixingDegree}</td>
                                </tr>
                                : null}

                            {dyesType && dyesType !== ''?
                                <tr key='prop-06'>
                                    <td><span className="options-table__key">Тип красителя</span>
                                    </td>
                                    <td>{dyesType}</td>
                                </tr>
                                : null}

                            {pallet && pallet !== ''?
                                <tr key='prop-05'>
                                    <td><span className="options-table__key">Палитра</span>
                                    </td>
                                    <td>{pallet}</td>
                                </tr>
                                : null}

                            {subGamma && subGamma !== ''?
                                <tr key='prop-10'>
                                    <td><span className="options-table__key">Подгамма</span>
                                    </td>
                                    <td>{subGamma}</td>
                                </tr>
                                : null}

                            {producerCountryName && producerCountryName !== ''?
                                <tr key='prop-08'>
                                    <td><span className="options-table__key">Страна производитель</span>
                                    </td>
                                    <td>{producerCountryName}</td>
                                </tr>
                                : null}

                            {cosmeticEffectName && cosmeticEffectName !== ''?
                                <tr key='prop-12'>
                                    <td><span className="options-table__key">Эффект</span>
                                    </td>
                                    <td>{cosmeticEffectName}</td>
                                </tr>
                                : null}

                            {productTextureName && productTextureName !== ''?
                                <tr key='prop-11'>
                                    <td><span className="options-table__key">Текстура продукта</span>
                                    </td>
                                    <td>{productTextureName}</td>
                                </tr>
                                : null}

                        </table>
                    </div>

                    <div  key={item.id + '7878' + index}  className="goods__controls">



                            {Width < WIDTH && activeMappingsCatalogListMobile2 !== "list" && viewCatalogClassMobile2 !== "goods-catalog__list--table" ?  <div style={{marginTop: '-10px'}} className="box-price">

                                <div style={Width<WIDTH ? {width: 150, paddingBottom: 10, }:{width: 250}} className="goods__price">
                                    <div  style={{paddingBottom: "-30px"}}>
                                        {isPriceOld ? <sup style={Width<WIDTH ? {fontSize:10, left: 50}:{fontSize:12, bottom: 30}} className="goods__price old-price">
                                            {((priceOld * parseFloat(vat) /100) + priceOld).toFixed(2)}  BYN
                                        </sup> : null}
                                    </div>
                                    <div>
                                        {price ? ((price * parseFloat(vat) /100) + price).toFixed(2) + ' BYN' : null}
                                    </div>
                                </div>
                            </div> :  <>{Width > WIDTH ?
                                <div className="box-price" >

                                    <div style={{width: 250}} className="goods__price">
                                    <span>
                                        {price ? ((price * parseFloat(vat) /100) + price).toFixed(2) + ' BYN' : null}
                                    </span>
                                        {isPriceOld ? <span  style={{paddingLeft: "20px"}} className="goods__price old-price">
                                            {((priceOld * parseFloat(vat) /100) + priceOld).toFixed(2)}  BYN
                                    </span>: null}
                                    </div>
                                </div> : null
                            }</>
                                 }



                        <BasketItem item={item} isDetail={false} type={type}/>

                    </div>
                </article>


            </>
        );
    }
}

import React from 'react';

import { observer } from "mobx-react";

import { AnyQuestions } from "../../components/block/AnyQuestions";
import { withStore } from "../../hocs";
import ReactGA from "react-ga";
import { YOUR_TRACKING_ID } from "../../stores/AppStore";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, TextField } from "@mui/material";



// @ts-ignore
@withStore
@observer
export default class AdminMessage extends React.Component {
    setGA = () => {
        ReactGA.initialize(YOUR_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentDidMount() {
        // @ts-ignore
        const { store } = this.props;
        store?.pageAdminAuthStore.getAllMessageViber();
        this.setGA();

    }

    render() {
        // @ts-ignore
        const { store } = this.props;
        const { arrMessageViber, onChangeArrayDataMessage, saveDataArrayMessage } = store?.pageAdminAuthStore;

        return (
            <>
                <main className="page__main">
                    <div className="container">
                        <ul className="breadcrumbs">
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link" href="/">Главная</a></li>
                            <li className="breadcrumbs__item"><a className="breadcrumbs__link"
                                href="/admin">Администрирование</a></li>
                            <li className="breadcrumbs__item">Правка Сообщений в Viber</li>
                        </ul>
                    </div>

                    <TableContainer component={Paper} style={{ width: "60%", alignItems: "center", justifyContent: "center", margin: "auto" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Приветствие</TableCell>
                                    <TableCell align="right">Главная Часть </TableCell>
                                    <TableCell align="right">Футер</TableCell>
                                    <TableCell align="right">Действие</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {arrMessageViber && arrMessageViber.length ? arrMessageViber.map((row: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="left">{row.json.greetings ? <TextField style={{ width: "100%" }} onChange={(e) => { onChangeArrayDataMessage(row.id, e.target.value, "greetings") }} defaultValue={row.json.greetings} id="outlined-basic" label="Приветствие" variant="outlined" rows={6} multiline /> : ""}</TableCell>
                                        <TableCell align="left">{row.json.mainText ? <TextField style={{ width: "100%" }} onChange={(e) => { onChangeArrayDataMessage(row.id, e.target.value, "mainText") }} defaultValue={row.json.mainText} id="outlined-basic" label="Главная Часть" variant="outlined" rows={6} multiline /> : ""}</TableCell>
                                        <TableCell align="left">{row.json.footer ? <TextField style={{ width: "100%" }} onChange={(e) => { onChangeArrayDataMessage(row.id, e.target.value, "footer") }} defaultValue={row.json.footer} id="outlined-basic" label="Футер" variant="outlined" rows={6} multiline /> : ""}</TableCell>
                                        <TableCell align="left"><Button style={{ backgroundColor: "#d1a0a9" }} onClick={() => saveDataArrayMessage(row.id)} variant="contained">Сохранить</Button></TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />
                    <AnyQuestions />
                </main>

            </>
        )
    }
}

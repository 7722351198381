import { action, makeAutoObservable } from "mobx";
import ElementModel from "../../model/ElementModel";
import { RootStore } from "../index";
import FavoriteModel from "../../model/FavoriteModel";
import BasketModel from "../../model/BasketModel";

export class PageCatalogStore {
  private rootStore: RootStore | undefined;
  arFavorite = [];
  arItemsSpecials = [];
  arMappingsCatalogList = [];
  activeMappingsCatalogList = "three-col";
  activeMappingsCatalogListMobile = "list";
  isPageBrend = false;
  brands = [];
  viewCatalogClass = "goods-catalog__list--grid";
  viewCatalogClassMobile = "goods-catalog__list--table";
  pagination = {
    countPage: 1,
    countRow: 1,
    currentPage: 1,
  };
  arItems = [];
  public error: string = "";

  orderModel: string = "";
  orderField: string = "name";
  orderBy: string = "ASC";
  createdAt: string = "";

  selectSortValue: string = "";

  isShow: boolean = false;
  userId: string = "";
  clientId: any = "";
  summAll = 0;
  summNds = 0;
  summBase = 0;
  arBasket: any = [];
  isSellout: any;
  arFilter: any = {};
  search: any = "";
  filter: any = "";
  productListKerastase = [];
  productListLoreal = [];
  productListMatrix = [];
  productListRedken = [];
  productListBiolage = [];
  limit: number = 18;
  showSkeleton: boolean = false;
  showSkeletonSearchResult: boolean = false;
  searchResult: any = [];
  isOpenMenuSearch: boolean = false;
  section: any = [];
  nextSearch: boolean = true
  nowIsActiveSearchAction: boolean = false

  constructor(rootStore?: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  @action
  onCurrentPage = (page: number) => {

    let url = window.location.pathname + "?" + page
    // @ts-ignore
    window.history.pushState(null, null, url);
    this.pagination.currentPage = page;
    this.init("", "", this.filter);

  };
  @action
  updateCurrentPage = () => {
    let url = window.location.pathname + "?1";
    // @ts-ignore
    window.history.pushState(null, null, url);
    this.pagination = {
      countPage: 1,
      countRow: 1,
      currentPage: 1,
    };
  };

  setIsShown() {
    this.isShow = !this.isShow;
  }

  toggleFIeldset = () => this.setIsShown();

  init(code1: string | undefined, code2: string | undefined, filter: string) {
    this.showSkeleton = true;
    let page = window.location.search;
    if (window.location.search.includes("q=")) {
      window.location.replace("/catalog?")
    }
    if (page.slice(1)) {
      this.pagination.currentPage = Number(page.slice(1));
    }
    this.filter = filter;

    if (this.search.length > 0) {
      this.reload(true);

    } else {
      this.rootStore?.componentFilterCatalogStore.init(filter);
      this.reload(true);
    }
    // @ts-ignore
    this.arMappingsCatalogList = this.getMappingsCatalogList();
  }

  @action
  setCatalogSpecials = (result: any) => {
    this.arItemsSpecials = result.data.rows;
  };

  @action
  setData = (result: any) => {
    this.setCatalog(result);
  };

  reload(init: boolean = false) {

    if (this.search.length > 0) {
      let clientId = this.rootStore?.mainStore.getClientId();
      let data = { q: this.search, clientId: clientId };
      this.nowIsActiveSearchAction = true
      ElementModel.searchCatalog(data, this.setCatalogSearch, this.setError);
    } else {
      this.nowIsActiveSearchAction = false
      ElementModel.getCatalog(
        {
          json: {
            clientId: this.rootStore?.mainStore.getClientId(),
            limit: this.limit,
            filter: this.setFilter(),
            sort: this.setSort(),
          },
          page: this.pagination.currentPage,
        },
        this.setCatalog,
        this.setError
      );
    }


    let f = this.setFilter();
    if (f?.props?.beautyHouseCategory?.length) {
      ElementModel.getBestsellers(
        {
          clientId: this.rootStore?.mainStore.getClientId(),
          categoryId: f?.props?.beautyHouseCategory[0],
        },
        this.setCatalogSpecials,
        this.setError
      );
    }
  }


  setFilter = () => {
    let f = { props: this.rootStore?.componentFilterCatalogStore.arFilter };
    if (this.search.length > 1) {
      //@ts-ignore
      f["props"]["name-menuFilter"] = this.search;
    } else {
      delete f["props"]["name-menuFilter"]
    }
    return f;
  };


  @action
  setPageBrend = (e: boolean) => {
    this.isPageBrend = e;
  };

  @action
  setFavorite(productId: any, userId: any, clientId: any) {
    let dto = {
      clientId: clientId,
      productId: productId,
      userId: userId,
    };
    FavoriteModel.create({ dto }, this.setCat, this.setError);
  }

  @action
  addFavorite = (productId: any, userId: any, clientId: any) => {
    this.clientId = clientId;
    this.userId = userId;
    let ob = {
      clientId: clientId,
      productId: productId,
      userId: userId,
    };
    FavoriteModel.addFavorite(ob, 0, this.setBasket, () => { });
  };
  @action
  deleteFavorite = (productId: any, userId: any, clientId: any) => {
    this.clientId = clientId;
    this.userId = userId;
    let ob = {
      clientId: clientId,
      productId: productId,
      userId: userId,
    };
    FavoriteModel.deleteFavorite(ob, 0, this.setBasket, () => { });
  };

  @action
  getFaforite = (item: any) => {
    FavoriteModel.getFavorite(item, 0, this.setBasket);
  };

  clearInputField = () => {
    localStorage.setItem("search", "");
    this.search = "";
    window.location.reload();
  };

  @action
  deleteAllFa = () => {
    const clientId = this.rootStore?.mainStore.clientId;
    FavoriteModel.deleteAllFa(
      { clientId: clientId },
      this.setBasket2,
      this.setError
    );
  };

  @action
  setBasket2 = () => {
    this.arFavorite = [];
    this.rootStore?.appStore.setPopupSubmitRemoveFavorite(false);
  };

  @action
  productDeleteAll = () => {
    const clientId = this.rootStore?.mainStore.getClientId();
    // @ts-ignore
    BasketModel.deleteAllBasket(
      {
        // @ts-ignore
        clientId: clientId,
      },
      this.setBasket,
      this.setError
    );
  };

  @action
  setBasket = (result: any) => {
    if (result.data) {
      this.summAll = 0;
      this.summNds = 0;
      this.summBase = 0;

      result.data.map((item: any) => {
        if (item?.product?.price?.final_price) {
          item.product.price.final_price = parseFloat(
            item?.product?.price?.final_price
          ).toFixed(2);
          let vat = parseFloat(item?.product?.propsNoFilter?.vat);
          let price = parseFloat(item?.product?.price?.final_price);
          let count = parseFloat(item.count);
          let Summ = parseFloat((count * price).toFixed(2));
          let Nds = parseFloat(((Summ * vat) / 100).toFixed(2));
          let SummNds = parseFloat((Summ + Nds).toFixed(2));
          item.product.price["Summ"] = Summ;
          item.product.price["Nds"] = Nds;
          item.product.price["SummNds"] = SummNds;
          this.summAll += SummNds;
          this.summNds += Nds;
          this.summBase += Summ;
          item["isMultiplicity"] = !!item.isMultiplicity;
          item["multiplicity"] = item?.product?.propsNoFilter?.inBox;
          item.count = parseFloat(item.count);
        }
        return item;
      });
      this.summAll = parseFloat(this.summAll.toFixed(2));
      this.summNds = parseFloat(this.summNds.toFixed(2));
      this.summBase = parseFloat(this.summBase.toFixed(2));
    }
    this.arFavorite = result.data;
    this.arBasket = this.rootStore?.pageBasketStore.arBasket;
  };

  @action
  onClickSearchButton = (e: any) => {
    // @ts-ignore
    window.history.pushState(null, null, "/catalog?1");
    this.reload(true);
  };

  @action
  onClickSearchButton2 = () => {
    // @ts-ignore
    window.history.pushState(null, null, "/catalog?1");
    this.reload(true);
  };

  @action
  onClearAndRemoveSearchInput = (e: any) => {

    this.pagination = {
      countPage: 1,
      countRow: 1,
      currentPage: 1,
    };
    this.search = "";
    localStorage.removeItem('search')
    let url = window.location.pathname;
    // @ts-ignore
    window.history.pushState(null, null, url);
    this.nowIsActiveSearchAction = false
    ElementModel.getCatalog(
      {
        json: {
          clientId: this.rootStore?.mainStore.getClientId(),
          limit: this.limit,
          filter: this.setFilter(),
          sort: this.setSort(),
        },
        page: this.pagination.currentPage,
      },
      this.setCatalog,
      this.setError
    );
    this.rootStore?.componentFilterCatalogStore.reload(true)

  };
  @action
  onPopupSearchInput = () => {
    this.isOpenMenuSearch = false;
  };

  @action
  onChangeInput = (e: any) => {
    this.isOpenMenuSearch = true;
    this.showSkeletonSearchResult = true
    this.search = e.target.value;
    this.pagination = {
      countPage: 1,
      countRow: 1,
      currentPage: 1,
    };
    let clientId = this.rootStore?.mainStore.getClientId();
    if (this.search.length > 2 && this.nextSearch) {
      this.nextSearch = true
      let data = { q: this.search, clientId: clientId, fast: true };
      this.nowIsActiveSearchAction = true
      ElementModel.searchCatalog(data, this.setSearch, this.setError);
      //ToDO commit for prod
      // ElementModel.search(data, this.setSearch, this.setError);
      //ToDO commit for prod
    }
  };

  @action
  setSearch = (result: any) => {
    this.showSkeletonSearchResult = false
    this.section = [];
    this.searchResult = result.data;
    for (let i = 0; i < result.data.sections.length; i++) {
      if (result.data.sections[i].value === "Окрашивание") {
        this.section.push({ name: result.data.sections[i].value, id: result.data.sections[i].id });
      } else if (result.data.sections[i].value === "Уход за волосами") {
        this.section.push({ name: result.data.sections[i].value, id: result.data.sections[i].id });
      } else if (result.data.sections[i].value === "Стайлинг") {
        this.section.push({ name: result.data.sections[i].value, id: result.data.sections[i].id });
      } else if (result.data.sections[i].value === "Для профессионалов") {
        this.section.push({ name: result.data.sections[i].value, id: result.data.sections[i].id });
      }
    }
    this.nextSearch = true
  };
  @action
  setCatalog2 = (result: any) => {
    this.arItems = result.data.rows;
    this.pagination = result.data.pagination;
  };

  @action
  setCatalog = (result: any) => {
    this.showSkeleton = false;
    this.isSellout = this.rootStore?.componentFilterCatalogStore.isSale;
    this.arItems = result.data.rows;
    this.pagination = result.data.pagination;
    this.search = "";
  };

  @action
  setCatalogSearch = (result: any) => {
    this.rootStore?.componentFilterCatalogStore.resetFilterAfterSearch()
    this.showSkeleton = false;
    this.isSellout = this.rootStore?.componentFilterCatalogStore.isSale;
    this.arItems = result.data.rows;
    this.pagination = result.data.pagination;
    let stringForFilter = ""
    if (this.arItems && this.arItems.length) {
      //@ts-ignore
      stringForFilter = `beautyHouseCategory-in-${this.arItems[0].props.beautyHouseCategory}+brand-in-${this.arItems[0].props.brand}`
      this.rootStore?.componentFilterCatalogStore.init(stringForFilter);
    }

    this.search = "";
  };

  addArray = () => {
    this.showSkeleton = true;
    let page = window.location.search;
    // @ts-ignore
    let newPage = Number(page.slice(1)) + 1;

    let url = window.location.pathname + "?" + newPage;
    // @ts-ignore
    window.history.pushState(null, null, url);
    this.pagination.currentPage = newPage;
    this.nowIsActiveSearchAction = false
    ElementModel.getCatalog(
      {
        json: {
          clientId: this.rootStore?.mainStore.getClientId(),
          limit: this.limit,
          filter: this.setFilter(),
          sort: this.setSort(),
        },
        page: this.pagination.currentPage,
      },
      this.setCatalogNewPage,
      this.setError
    );
  };

  @action
  setCatalogNewPage = (result: any) => {
    if (result && result.data.rows) {
      // @ts-ignore
      result.data.rows.forEach((item: any) => this.arItems.push(item));
    }
    this.showSkeleton = false;
  };

  setCat = () => {
    console.log("result");
  };

  @action
  setError = (error: any) => {
    this.nextSearch = true
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.data !== undefined &&
      error.response.data.message !== undefined
    ) {
      this.error = String(error.response.data.message);
    }
  };

  @action
  setMappingsCatalogList = (e: any, key: string, key_class: string) => {
    e.preventDefault();
    e.stopPropagation();
    this.activeMappingsCatalogList = key;
    this.activeMappingsCatalogListMobile = key;
    this.viewCatalogClass = key_class;
    this.viewCatalogClassMobile = key_class;
  };

  @action
  getParamGET() {
    let params = window.location.search
      .replace("?", "")
      .split("&")
      .reduce(function (p, e) {
        let a = e.split("=");
        // @ts-ignore
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      }, {});
    // @ts-ignore
    if (+params["page"] > 0) {
      // @ts-ignore
      return params["page"];
    } else {
      return false;
    }
  }

  @action
  getMappingsCatalogList() {
    return [
      {
        id: "three-col",
        src: "/images/sprite.svg#icon--three-col",
        title: "По три в ряд",
        class: "goods-catalog__list--grid",
      },
      {
        id: "full-list",
        src: "/images/sprite.svg#icon--full-list",
        title: "Полный список",
        class: "goods-catalog__list--list",
      },
      {
        id: "list",
        src: "/images/sprite.svg#icon--short-list",
        title: "Краткий список",
        class: "goods-catalog__list--table",
      },
    ];
  }

  private setSort() {
    let sortArray = window.localStorage.getItem("sortArray");
    if (sortArray) {
      let array = JSON.parse(sortArray);
      this.selectSortValue = array.selectSortValue;
      return {
        orderModel: array.orderModel,
        orderField: array.orderField,
        orderBy: array.orderBy,
      };
    } else {
      return {
        orderModel: this.orderModel,
        orderField: this.orderField,
        orderBy: this.orderBy,
      };
    }
  }
  getAllCatalogList = () => {
    let clientId = this.rootStore?.mainStore.getClientId();
    ElementModel.getAllCatalogList(
      {
        json: {
          clientId: clientId,
          filter: { props: { brand: [30084] } },
          sort: this.setSort(),
        },
      },
      this.setProductListKerastase,
      this.setError
    );
    ElementModel.getAllCatalogList(
      {
        json: {
          clientId: clientId,
          filter: { props: { brand: [30176] } },
          sort: this.setSort(),
        },
      },
      this.setProductListLoreal,
      this.setError
    );
    ElementModel.getAllCatalogList(
      {
        json: {
          clientId: clientId,
          filter: { props: { brand: [30190] } },
          sort: this.setSort(),
        },
      },
      this.setProductListMatrix,
      this.setError
    );
    ElementModel.getAllCatalogList(
      {
        json: {
          clientId: clientId,
          filter: { props: { brand: [30255] } },
          sort: this.setSort(),
        },
      },
      this.setProductListRedken,
      this.setError
    );
    ElementModel.getAllCatalogList(
      {
        json: {
          clientId: clientId,
          filter: { props: { brand: [49108] } },
          sort: this.setSort(),
        },
      },
      this.setProductListBiolage,
      this.setError
    );
  };
  @action
  setProductListKerastase = (result: any) => {
    this.productListKerastase = result.data.rows;
    this.productListKerastase = this.productListKerastase.sort(function (
      a: any,
      b: any
    ) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  };
  @action
  setProductListLoreal = (result: any) => {
    this.productListLoreal = result.data.rows;
    this.productListLoreal = this.productListLoreal.sort(function (
      a: any,
      b: any
    ) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  };
  @action
  setProductListMatrix = (result: any) => {
    this.productListMatrix = result.data.rows;
    this.productListMatrix = this.productListMatrix.sort(function (
      a: any,
      b: any
    ) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  };
  @action
  setProductListRedken = (result: any) => {
    this.productListRedken = result.data.rows;
    this.productListRedken = this.productListRedken.sort(function (
      a: any,
      b: any
    ) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  };

  @action
  setProductListBiolage = (result: any) => {
    this.productListBiolage = result.data.rows;
    this.productListBiolage = this.productListBiolage.sort(function (
      a: any,
      b: any
    ) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  };

  @action
  public setSortParams = (orderField: string) => {

    this.toggleFIeldset();

    if (!this.nowIsActiveSearchAction) {
      if (orderField === "priceUp") {
        this.selectSortValue = "по возрастанию цены";
        this.orderModel = "prices";
        this.orderBy = "ASC";
        this.orderField = "price";
        let sortArray = {
          selectSortValue: this.selectSortValue,
          orderModel: this.orderModel,
          orderBy: this.orderBy,
          orderField: this.orderField,
        };
        window.localStorage.setItem("sortArray", JSON.stringify(sortArray));
        this.reload();
      }
      if (orderField === "priceDown") {
        this.selectSortValue = "по убыванию цены";
        this.orderModel = "prices";
        this.orderBy = "DESC";
        this.orderField = "price";
        let sortArray = {
          selectSortValue: this.selectSortValue,
          orderModel: this.orderModel,
          orderBy: this.orderBy,
          orderField: this.orderField,
        };
        window.localStorage.setItem("sortArray", JSON.stringify(sortArray));
        this.reload();
      }
      if (orderField === "name") {
        this.selectSortValue = "по наименованию";
        this.orderModel = "";
        this.orderBy = "ASC";
        this.orderField = "name";

        let sortArray = {
          selectSortValue: this.selectSortValue,
          orderModel: this.orderModel,
          orderBy: this.orderBy,
          orderField: this.orderField,
        };
        window.localStorage.setItem("sortArray", JSON.stringify(sortArray));
        this.reload();
      }
      if (orderField === "createdAt") {
        this.selectSortValue = "по новизне";
        this.orderModel = "";
        this.orderBy = "DESC";
        this.orderField = "createdAt";
        let sortArray = {
          selectSortValue: this.selectSortValue,
          orderModel: this.orderModel,
          orderBy: this.orderBy,
          orderField: this.orderField,
        };
        window.localStorage.setItem("sortArray", JSON.stringify(sortArray));
        this.reload();
      }
    } else {
      if (orderField === "priceUp") {
        this.selectSortValue = "по возрастанию цены";
        // @ts-ignore
        this.arItems.sort((a, b) => a.price.final_price - b.price.final_price);
      }
      if (orderField === "priceDown") {
        this.selectSortValue = "по убыванию цены";
        // @ts-ignore
        this.arItems.sort((a, b) => b.price.final_price - a.price.final_price);
      }
      if (orderField === "name") {
        this.selectSortValue = "по наименованию";
        this.arItems.sort((a, b) => {
          // @ts-ignore
          if (a.name > b.name) {
            return 1;
          }
          // @ts-ignore
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      }
      if (orderField === "createdAt") {
        this.selectSortValue = "по новизне";
        // @ts-ignore
        this.arItems.sort((a, b) => b.createdAt - a.createdAt);
      }
    }

  };
}

import { action, makeAutoObservable } from "mobx"
import { RootStore } from "../index";
import BasketModel from "../../model/BasketModel";
import ClientModel from "../../model/ClientModel";
import DeliveryModel from "../../model/DeliveryModel";
import PaymentModel from "../../model/PaymentModel";
import OrderModel from "../../model/OrderModel";
import { APP_SITE_ID } from "../../const/root";
import { enumOrderStatus } from "../../enum/enumOrderStatus";
import { uuidv4 } from "../../helper/uid";
import FavoriteModel from "../../model/FavoriteModel";
import moment from "moment";


export const APP_DELIVERY_TYPE_AUTODELIVERY = 1;   ////  способ доставки Автодоставка
export const APP_DELIVERY_TYPE_COURIER = 2;        ////  способ доставки Курьерская
export const APP_DELIVERY_TYPE_COURIER_DLY_FIZLICO = 4;        ////  способ доставки Курьерской службой
export const APP_DELIVERY_TYPE_TAKEAWAY = 3;       ////  способ доставки Самовывоз

export const APP_PAYMENT_TYPE_NONCASH = 5;          ////  способ оплаты  Безналичные
export const APP_PAYMENT_TYPE_CASH = 4;             ////  способ оплаты  Наличные
export const APP_PAYMENT_TYPE_WEBPAY = 7;             ////  способ оплаты  WEBPAY

export const APP_ORG_TYPE_FIZLICO = 3;              ////  id для физлица ("fizlico")
export const APP_ORG_TYPE_URLICO = 4;               ////  id для юрлица ("urlico")


export class PageBasketStore {
    private rootStore: RootStore | undefined;

    arBasket = [];
    note = '';
    error = '';
    discount2 = 0
    showBtnSend = true;
    allItemsId = [];
    addCountFavoriteToBasket: number = 1;
    selectedOption = {
        Client: { value: 0, label: '' },
        DeliveryType: { value: 1, label: '' }, /// способ доставки (для юрлица по default Автодоставка id = 1 )
        PaymentType: { value: 0, label: '' },
        Dates: { value: 0, label: '', },
        Points: { value: 0, label: '', amount: 0 },
        Note: { value: '', label: '', amount: 0 },
        MethodPay: { value: APP_PAYMENT_TYPE_NONCASH, label: '' },        /// способ оплаты (для юрлица по default Безналичные id = 5  )
        PaymentTypeId: { value: APP_PAYMENT_TYPE_NONCASH, label: '' },    /// способ оплаты (для юрлица по default Безналичные id = 5  )
        ClientShipDates: { value: "", label: '' },    /// Отображение интервалов дат , если физлицо Наличные и Курьерская
    }
    isModalOpen = false;
    isModalOpen2 = false;
    isClosePaymentDate = false;
    setSelectedOption = {
        Client: null,
        DeliveryType: null,
        PaymentType: null,
        Dates: null,
        ClientShipDates: null,
        Points: null,
        Note: null,
        MethodPay: null,
        PaymentTypeId: 0,
    }
    options = {
        Client: [],
        DeliveryType: [],
        PaymentType: [],
        Dates: [],
        ClientShipDates: [],
        Points: [],
        Note: '',
        MethodPay: '',
        PaymentTypeId: [],
    }
    summAll = 0;
    summNds = 0;
    summBase = 0;
    flagPaymentType = 0;
    orderId = 0
    nanSymbol = false
    name = ""
    phone = ""
    org_type = ""
    org_type_id = 0
    isPopupWebPay = false
    linkWebPay = ""
    textAboutErrorWebPayLink = false
    openConfirm = false
    loginUser = ""
    isPopupWebPayAboutMinSum = false
    scores = 0
    futureScores = 0
    showMessageAboutBlockAction = false
    scoresConst = 0
    arrWithDateAndDeliveryType = []
    arrDeliveryTypeForProductPage = []

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        // @ts-ignore
        this.setSelectedOption = {
            // @ts-ignore
            Client: this.setSelectedOptionClient,
            // @ts-ignore
            DeliveryType: this.setSelectedOptionDeliveryType,
            // @ts-ignore
            PaymentType: this.setSelectedOptionPaymentType,
            // @ts-ignore
            Dates: this.setSelectedOptionDates,
            // @ts-ignore
            ClientShipDates: this.setSelectedOptionClientShipDates,
            // @ts-ignore
            Points: this.setSelectedOptionPoints,
            // @ts-ignore
            Note: this.setSelectedOptionNote,
            // @ts-ignore
            MethodPay: this.setSelectedOptionMethodPay,
        }
    }


    setSelectedOptionClient = (e: any) => {
        this.selectedOption.Client = e;
    }

    setSelectedOptionDeliveryType = (item: any) => {
        if (this.selectedOption.Dates && this.selectedOption.Dates.value && this.selectedOption.Dates.label) {
            this.selectedOption.Dates.value = 0
            this.selectedOption.Dates.label = ""
        }
        this.selectedOption.DeliveryType = item;
        let clientId = this.rootStore?.mainStore.getClientId();
        ////// Самовывоз
        if (item.value === APP_DELIVERY_TYPE_TAKEAWAY && this.org_type_id === APP_ORG_TYPE_FIZLICO) {
            // @ts-ignore
            this.options.PaymentTypeId.filter((item) => item.value !== APP_PAYMENT_TYPE_WEBPAY)
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_WEBPAY  //// способ оплаты WEBPAY
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_WEBPAY   //// способ оплаты WEBPAY
            DeliveryModel.getDates(0, APP_DELIVERY_TYPE_TAKEAWAY, this.setDates, this.setError);
        } else if (item.value === APP_DELIVERY_TYPE_TAKEAWAY && this.org_type_id !== APP_ORG_TYPE_FIZLICO) {
            DeliveryModel.getDates(0, APP_DELIVERY_TYPE_TAKEAWAY, this.setDates, this.setError);
        }
        ///// Автодоставка
        if (item.value === APP_DELIVERY_TYPE_AUTODELIVERY) {
            DeliveryModel.getPoints(clientId, this.getPoints, this.setError);
        }
        ///// Курьерская
        if (item.value === APP_DELIVERY_TYPE_COURIER) {
            // @ts-ignore
            this.options.PaymentTypeId.filter((item) => item.value !== APP_PAYMENT_TYPE_WEBPAY)
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_CASH  //// способ оплаты наличные
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_CASH   //// способ оплаты наличные
            DeliveryModel.getDates(this.selectedOption.Points.value, APP_DELIVERY_TYPE_COURIER, this.setDates, this.setError);
        }
        ///// Курьерская физлицо
        if (item.value === APP_DELIVERY_TYPE_COURIER_DLY_FIZLICO) {
            // @ts-ignore
            this.options.PaymentTypeId.filter((item) => item.value !== APP_PAYMENT_TYPE_WEBPAY)
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_CASH  //// способ оплаты наличные
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_CASH   //// способ оплаты наличные
            DeliveryModel.getDates(this.selectedOption.Points.value, APP_DELIVERY_TYPE_COURIER, this.setDates, this.setError);
        }
    }

    setSelectedOptionMethodPayType = (item: any) => {
        this.selectedOption.PaymentTypeId.value = item
        this.selectedOption.MethodPay.value = item;
        this.flagPaymentType = item
        let paymentId = item
        DeliveryModel.getTypes(paymentId, this.setDeliveryType, this.setError);
        if (item === APP_PAYMENT_TYPE_CASH) {
            this.isClosePaymentDate = true
            this.selectedOption.PaymentType.value = 0
            this.discount2 = 0
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_CASH  //// способ оплаты Наличные
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_CASH   //// способ оплаты Наличные
            this.selectedOption.DeliveryType.value = APP_DELIVERY_TYPE_COURIER /// Курьерская доставка
            DeliveryModel.getDates(this.selectedOption.Points.value, APP_DELIVERY_TYPE_COURIER, this.setDates, this.setError); //// даты для курьерской доставки
        } else if (item === APP_PAYMENT_TYPE_NONCASH) {
            this.isClosePaymentDate = false
            let clientId = this.rootStore?.mainStore.getClientId();
            this.selectedOption.DeliveryType.value = APP_DELIVERY_TYPE_AUTODELIVERY /// способ доставки Автодоставка
            DeliveryModel.getPoints(clientId, this.getPoints, this.setError); /// получаем даты доставки Автодоставка
        } else if (item === APP_PAYMENT_TYPE_WEBPAY) {
            this.isClosePaymentDate = true
            this.selectedOption.PaymentType.value = 0
            this.discount2 = 0
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_WEBPAY  //// способ оплаты WEBPAY
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_WEBPAY   //// способ оплаты WEBPAY
            this.selectedOption.DeliveryType.value = APP_DELIVERY_TYPE_TAKEAWAY /// способ доставки Самовывоз
            DeliveryModel.getDates(this.selectedOption.Points.value, APP_DELIVERY_TYPE_COURIER, this.setDates, this.setError); //// даты для курьерской доставки
        } else {
            this.isClosePaymentDate = false
        }
        this.getBasket();
    }

    setSelectedOptionPaymentType = (e: any) => {
        this.discount2 = e.value
        this.selectedOption.PaymentType.value = e.value
        this.getBasket();
    }

    setSelectedOptionDates = (e: any) => {
        this.selectedOption.ClientShipDates.value = ""
        this.selectedOption.Dates = e;
        if (this.getOpenModal2() && this.selectedOption.DeliveryType.value !== APP_DELIVERY_TYPE_TAKEAWAY) {
            this.isModalOpen2 = true
        }
    }

    setSelectedOptionClientShipDates = (e: any) => {
        this.selectedOption.ClientShipDates = e;
        this.selectedOption.Dates = e;
        if (this.getOpenModal2() && this.selectedOption.DeliveryType.value !== APP_DELIVERY_TYPE_TAKEAWAY) {
            this.isModalOpen2 = true
        }
    }

    setSelectedOptionPoints = (e: any) => {
        this.selectedOption.Points = e;
        this.selectedOption.Dates = { value: 0, label: 'Выберите дату' };
        this.options.Dates = [];
        DeliveryModel.getDates(this.selectedOption.Points.value, this.selectedOption.DeliveryType.value, this.setDates, this.setError);
        this.getNewDate()
    }

    setSelectedOptionNote = (e: any) => {
        this.selectedOption.Note.value = e.target.value;
    }

    setSelectedOptionMethodPay = (item: any) => {
        this.selectedOption.MethodPay = item;
    }

    @action
    minusCount = (item: any) => {
        if (item.basket.count > 1) {
            item.basket.count--;
            BasketModel.addProduct(item.basket, 0, this.setBasket, this.setError);
        }
    }

    @action
    minusCountFavorite = (item: any) => {
        if (item.count > 1) {
            item.count--;
            BasketModel.addProduct(item, 0, this.setBasket, this.setError);
        }
    }

    @action
    productPlus = (item: any) => {
        if (item.isMultiplicity) {
            item.count += item.multiplicity;
        } else {
            item.count++;
        }
        this.changeProduct(item);
    }

    @action
    productMinus = (item: any) => {
        if (item.isMultiplicity) {
            if (item.count - item.multiplicity < item.multiplicity) {
                item.count = item.multiplicity;
            } else {
                item.count -= item.multiplicity;
            }
        } else {
            item.count--;
        }
        if (!item.count) {
            item.count = 1;
            return;
        }
        this.changeProduct(item);
    }

    @action setMultiplicity = (item: any, isValue: boolean) => {
        item.isMultiplicity = isValue;
        if (isValue) {
            if (item.count < item.multiplicity) {
                item.count = item.multiplicity;
            } else {
                item.count = item.count - (item.count % item.multiplicity);
            }
            this.changeProduct(item);
        }
    }

    @action changeProductInput = (e: { target: { value: string } }, item: any) => {
        let count = parseInt(e.target.value);
        if (item.isMultiplicity) {
            if (count) {
                count = count - (count % item.multiplicity);
                if (!count) {
                    item.count = item.multiplicity;
                } else {
                    item.count = count;
                }
            } else {
                item.count = item.multiplicity;
            }
        } else {
            if (count) {
                item.count = count;
            } else {
                item.count = 1;
            }
        }
        this.changeProduct(item);
    }

    @action changeProductInputCatalogPage = (e: { target: { value: string } }, item: any) => {
        item.basket.count = e.target.value;
        BasketModel.addProduct(item.basket, 0, this.setBasket, this.setError);
    }

    @action changeProductInputFavorite = (e: { target: { value: string } }, item: any) => {
        item.count = e.target.value;
        BasketModel.addProduct(item, 0, this.setBasket, this.setError);
    }

    @action init() {
        if (this.rootStore?.mainStore.isAuth) {
            this.isModalOpen = false;
            ClientModel.getClients(this.setClients, this.setError);
            this.rootStore?.pageCatalogStore.getFaforite(this.rootStore.mainStore.clientId);
            this.phone = this.rootStore.mainStore.phoneeForOrderInfo
            this.name = this.rootStore.mainStore.fullNameForOrderInfo
            this.org_type = this.rootStore.mainStore.org_type
            this.loginUser = this.rootStore.mainStore.loginUser
            if (this.org_type === "fizlico") {
                this.org_type_id = APP_ORG_TYPE_FIZLICO
                this.reload();
            } else {
                this.org_type_id = APP_ORG_TYPE_URLICO
                this.reload();
            }
        }
    }

    @action initForProductPage() {
        if (this.rootStore?.mainStore.isAuth) {
            ClientModel.getClients(this.setClients, this.setError);
            this.org_type = this.rootStore.mainStore.org_type
            if (this.org_type === "fizlico") {
                this.org_type_id = APP_ORG_TYPE_FIZLICO
            } else {
                this.org_type_id = APP_ORG_TYPE_URLICO
            }
            this.reloadForProductPage();
        }
    }


    @action reload() {
        this.isPopupWebPay = false
        this.linkWebPay = ""
        DeliveryModel.getPaymentClientTypeId(this.org_type_id, this.setPaymentTypeByTypeOrgId, this.setError)
    }

    @action reloadForProductPage() {
        DeliveryModel.getTypes(0, this.setDeliveryTypeForProductPage, this.setError); // способы доставки
    }

    @action
    setPaymentTypeByTypeOrgId = (result: any) => {
        if (result.data.length) {
            const filterResultData = result.data.filter((el => (f: any) => !el.has(f.payment.name) && el.add(f.payment.name))(new Set()));
            result.data = filterResultData
            //фильтрация , что бы наличная оплата была первой
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].payment.name === "WEBPAY") {
                    result.data.push(result.data[i])
                    result.data.splice(i, 1);
                }
            }
        }

        this.setData(result, 'PaymentTypeId', 'name');
        /// Получаем способы доставки
        if (this.org_type_id === APP_ORG_TYPE_URLICO) {
            let clientId = this.rootStore?.mainStore.getClientId();
            PaymentModel.getTypes(clientId, this.setPaymentType, this.setError);
            DeliveryModel.getTypes(APP_PAYMENT_TYPE_NONCASH, this.setDeliveryType, this.setError);  /// для юрлица способы доставки при Безналичном расчете
            DeliveryModel.getPoints(clientId, this.getPoints, this.setError); /// получаем даты доставки Автодоставка
        } else if (this.org_type_id === APP_ORG_TYPE_FIZLICO) {
            this.flagPaymentType = APP_PAYMENT_TYPE_CASH  /// Отключаем проверку по Срок оплаты
            this.isClosePaymentDate = true /// Скрываем срок оплаты
            let clientId = this.rootStore?.mainStore.getClientId();
            DeliveryModel.getPoints(clientId, this.getPoints, this.setError); /// получаем даты доставки Автодоставка
            PaymentModel.getTypes(clientId, this.setPaymentType, this.setError);
            // DeliveryModel.getTypes(APP_PAYMENT_TYPE_CASH, this.setDeliveryType, this.setError);/// для физ лица способы доставки при Наличном расчете
            DeliveryModel.getTypes(APP_PAYMENT_TYPE_CASH, this.setDeliveryType, this.setError);/// для физ лица способы доставки при Наличном расчете
            this.selectedOption.MethodPay.value = APP_PAYMENT_TYPE_CASH  //// способ оплаты Наличные
            this.selectedOption.PaymentTypeId.value = APP_PAYMENT_TYPE_CASH   //// способ оплаты Наличные
            this.selectedOption.DeliveryType.value = APP_DELIVERY_TYPE_COURIER /// Курьерская доставка
            DeliveryModel.getDates(this.selectedOption.Points.value, APP_DELIVERY_TYPE_COURIER, this.setDates, this.setError);  /// получаем даты доставки Курьерская
        }
        this.getBasket();
    }

    @action
    changeName = (value: any) => {
        this.name = value
    }

    @action
    changePhone = (value: any) => {
        this.phone = value
    }

    setData(result: any, keyData: string, keyName: string, keyField: string = '') {
        // @ts-ignore
        this.options[keyData] = [];
        if (keyData === "PaymentTypeId" && result.data?.length) {
            result.data.map((item: any) => {
                // @ts-ignore
                this.options[keyData].push({
                    // @ts-ignore
                    value: item?.payment.id,
                    // @ts-ignore
                    label: item.payment[keyName],
                });
                return item;
            });
        }
        if (result.data?.length && keyData !== "PaymentTypeId") {
            result.data.map((item: any) => {
                if (keyField.length) {
                    // @ts-ignore
                    this.options[keyData].push({
                        // @ts-ignore
                        value: item?.id,
                        // @ts-ignore
                        label: item[keyName],
                        // @ts-ignore
                        [keyField]: item[keyField]
                    });
                } else {
                    // @ts-ignore
                    this.options[keyData].push({
                        // @ts-ignore
                        value: item?.id,
                        // @ts-ignore
                        label: item[keyName],
                    });
                }
                return item;
            });
            if (keyField.length) {
                for (let i = 0; i < result.data.length; i++) {
                    if (result.data[i].isDefault) {
                        // @ts-ignore
                        this.selectedOption[keyData] = {
                            // @ts-ignore
                            value: result.data[i]?.id,
                            // @ts-ignore
                            label: result.data[i][keyName],
                            // @ts-ignore
                            [keyField]: result.data[i][keyField]
                        }
                    }
                }
                // @ts-ignore
                if (this.selectedOption[keyData].value === 0 && this.selectedOption[keyData].label === "") {
                    // @ts-ignore
                    this.selectedOption[keyData] = {
                        // @ts-ignore
                        value: result.data[0]?.id,
                        // @ts-ignore
                        label: result.data[0][keyName],
                        // @ts-ignore
                        [keyField]: result.data[0][keyField]
                    }
                }
            } else {
                // @ts-ignore
                this.selectedOption[keyData] = {
                    // @ts-ignore
                    value: result.data[0]?.id,
                    // @ts-ignore
                    label: result.data[0][keyName],
                }
            }
        }

        if (this.org_type_id === APP_ORG_TYPE_URLICO && this.selectedOption.PaymentTypeId.value === APP_DELIVERY_TYPE_COURIER_DLY_FIZLICO) {
            console.log(this.selectedOption.PaymentTypeId)
            for (let i = 0; i < this.options.Dates.length; i++) {
                // @ts-ignore
                if (moment(this.options.Dates[i].value).weekday() !== 3) {
                    this.options.Dates.splice(i, 1)
                }
            }
            this.setSelectedOptionDates(this.options.Dates[0])
        }
    }

    @action
    setDeliveryType = (result: any) => {
        if (this.org_type_id === APP_ORG_TYPE_FIZLICO && result.data.length) {
            result.data.forEach((item: any) => item.id === APP_DELIVERY_TYPE_COURIER_DLY_FIZLICO ? item.id = APP_DELIVERY_TYPE_COURIER : item.id)
            result.data.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
        }
        this.setData(result, 'DeliveryType', 'name');
    }

    @action
    setDeliveryTypeForProductPage = (result: any) => {
        this.arrDeliveryTypeForProductPage = result.data
        let clientId = this.rootStore?.mainStore.getClientId();
        DeliveryModel.getPoints(clientId, this.getPointsForProductPage, this.setError); // адреса доставок
    }

    @action
    setDates = (result: any) => {
        this.setData(result, 'Dates', 'name');
        this.setData(result, 'ClientShipDates', 'clientShipDates');
    }

    @action
    getPoints = (result: any) => {
        this.setData(result, 'Points', 'name', 'amount');
        DeliveryModel.getDates(this.selectedOption.Points.value, this.selectedOption.DeliveryType.value, this.setDates, this.setError);
    }
    @action
    getPointsForProductPage = (result: any) => {
        // @ts-ignore
        this.org_type = this.rootStore.mainStore.org_type
        this.setData(result, 'Points', 'name', 'amount');
        for (let i = 0; i < this.arrDeliveryTypeForProductPage.length; i++) {
            const setDatesForProductPage = (result: any) => {
                // @ts-ignore
                if (this.arrDeliveryTypeForProductPage[i].name === "Курьерская" && this.org_type !== "fizlico") {
                    let a = result.data
                    for (let i = 0; i < a.length; i++) {
                        // @ts-ignore
                        if (moment(a[i].id).weekday() !== 3) {
                            a.splice(i, 1)
                        }
                    }
                    // @ts-ignore
                    this.arrDeliveryTypeForProductPage[i]["deliveryTime"] = a
                } else {
                    // @ts-ignore
                    this.arrDeliveryTypeForProductPage[i]["deliveryTime"] = result.data
                }
            }
            let id = 0
            // @ts-ignore
            if (this.arrDeliveryTypeForProductPage[i].name === "Курьерской службой") {
                id = APP_DELIVERY_TYPE_COURIER
            } else {
                // @ts-ignore
                id = this.arrDeliveryTypeForProductPage[i].id
            }
            // @ts-ignore
            if (this.arrDeliveryTypeForProductPage[i].name === "Самовывоз") {
                // @ts-ignore
                DeliveryModel.getDates(0, id, setDatesForProductPage, this.setError);
            } else {
                // @ts-ignore
                DeliveryModel.getDates(this.selectedOption.Points.value, id, setDatesForProductPage, this.setError);
            }
        }
    }
    @action
    getNewDate = () => {
        for (let i = 0; i < this.arrDeliveryTypeForProductPage.length; i++) {
            const setDatesForProductPage = (result: any) => {
                // @ts-ignore
                if (this.arrDeliveryTypeForProductPage[i].name === "Курьерская") {
                    let a = result.data
                    for (let i = 0; i < a.length; i++) {
                        // @ts-ignore
                        if (moment(a[i].value).weekday() !== 3) {
                            a.splice(i, 1)
                        }
                    }
                    // @ts-ignore
                    this.arrDeliveryTypeForProductPage[i]["deliveryTime"] = a
                } else {
                    // @ts-ignore
                    this.arrDeliveryTypeForProductPage[i]["deliveryTime"] = result.data
                }
            }
            let id = 0
            // @ts-ignore
            if (this.arrDeliveryTypeForProductPage[i].name === "Курьерской службой") {
                id = APP_DELIVERY_TYPE_COURIER
            } else {
                // @ts-ignore
                id = this.arrDeliveryTypeForProductPage[i].id
            }
            // @ts-ignore
            if (this.arrDeliveryTypeForProductPage[i].name === "Самовывоз") {
                // @ts-ignore
                DeliveryModel.getDates(0, id, setDatesForProductPage, this.setError);
            } else {
                // @ts-ignore
                DeliveryModel.getDates(this.selectedOption.Points.value, id, setDatesForProductPage, this.setError);
            }
        }
    }

    @action
    setPaymentType = (result: any) => {
        this.setData(result, 'PaymentType', 'name');
        this.getBasket();
    }

    @action
    getBasket() {
        if (window.location.pathname === '/basket') {
            let clientId = this.selectedOption?.Client?.value;
            BasketModel.getBasket(clientId, 0, this.setBasket, this.setError);
        } else {
            let clientId = this.selectedOption?.Client?.value;
            let discount = this.discount2
            BasketModel.getBasket(clientId, discount, this.setBasket, this.setError);
        }
    }

    @action
    getFavorite = () => {
        let clientId = this.rootStore?.mainStore.clientId;
        FavoriteModel.getFavorite(clientId, 0, this.setBasket, this.setError);
    }

    @action
    setClients = (result: any) => {
        this.setData(result, 'Client', 'fullName');
    }

    @action
    addCount = (item: any) => {
        item.basket.count++;
        BasketModel.addProduct(item.basket, 0, this.setBasket, this.setError);
    }

    @action
    addCountFavorite = (item: any) => {
        item.count = this.addCountFavoriteToBasket++
        BasketModel.addProduct(item, 0, this.setBasket, this.setError);
    }

    @action
    addProduct = (item: any) => {
        if (!item.basket.count) {
            item.basket.count = 1;
        }
        BasketModel.addProduct(item.basket, 0, this.setBasket, this.setError);
    }

    @action
    addProductFavorite = (item: any) => {
        if (!item.count) {
            item.count = 1;
        }
        BasketModel.addProduct(item, 0, this.setBasket, this.setError);
    }

    @action
    changeProduct = (item: any) => {
        BasketModel.addProduct(item, 0, this.setBasket, this.setError);
    }

    @action
    productDelete = (item: any) => {
        const clientId = this.rootStore?.mainStore.getClientId();
        // @ts-ignore
        BasketModel.deleteProduct({
            id: item.id,
            clientId: clientId,
            // @ts-ignore
            discount: 0
        }, this.setBasket, this.setError);
    }

    @action
    productDeleteAll = () => {
        const clientId = this.rootStore?.mainStore.getClientId();
        // @ts-ignore
        BasketModel.deleteAllBasket({
            // @ts-ignore
            clientId: clientId,
        }, this.setBasket, this.setError);
    }

    @action
    setBasket = (result: any) => {
        this.scoresConst = 0
        this.summAll = 0;
        this.summNds = 0;
        this.summBase = 0;
        this.scores = 0
        this.showBtnSend = true;
        this.showMessageAboutBlockAction = false
        if (result.data) {
            result.data.map((item: any) => {
                if (item?.product?.price?.final_price) {
                    item.product.price.final_price = parseFloat(item?.product?.price?.final_price).toFixed(2);
                    let vat = parseFloat(item?.product?.propsNoFilter?.vat);
                    let price = parseFloat(item?.product?.price?.final_price);
                    let count = parseFloat(item.count);
                    let Summ = parseFloat((count * price).toFixed(2));
                    let Nds = parseFloat((Summ * vat / 100).toFixed(2));
                    let SummNds = parseFloat((Summ + Nds).toFixed(2));
                    let maxScoresForItem = parseFloat(item?.product?.price?.max_scores);
                    item.product.price['Summ'] = Summ;
                    item.product.price['Nds'] = Nds
                    item.product.price['SummNds'] = SummNds;
                    this.summAll += SummNds;
                    this.summNds += Nds;
                    this.summBase += Summ;

                    item['isMultiplicity'] = !!item.isMultiplicity;
                    item['multiplicity'] = item?.product?.propsNoFilter?.inBox;
                    item.count = parseFloat(item.count);
                    this.scores += count * maxScoresForItem
                    this.scoresConst += count * maxScoresForItem
                    this.scoresConst = parseFloat(this.scoresConst.toFixed(2));

                    if (this.showBtnSend && item.count > item?.product?.stocks[0]?.amount) {
                        this.showBtnSend = false;
                    }

                    if (item?.product?.stocks[0]?.amount === 0) {
                        this.showBtnSend = false;
                    }
                }
                this.allItemsId = item.id
                return item;
            });
            this.summAll = parseFloat(this.summAll.toFixed(2));
            this.summNds = parseFloat(this.summNds.toFixed(2));
            this.summBase = parseFloat(this.summBase.toFixed(2));

            this.scores = parseFloat(this.scores.toFixed(2));
            // @ts-ignore
            if (this.rootStore?.mainStore.scoresAmount < this.scores) {
                this.scores = Number(this.rootStore?.mainStore.scoresAmount)
            }
            let scores = this.scores
            if (scores === 0) {
                this.showMessageAboutBlockAction = true
            }
            let clientId = this.rootStore?.mainStore.getClientId();
            let discount = this.selectedOption?.PaymentType?.value;
            BasketModel.getFutureLoyaltyScores(clientId, discount, scores, this.setLoyaltyScores, this.setError)
        }

        this.arBasket = result.data;
        // this.showBtnSend = true
        for (let i = 0; i < this.arBasket.length; i++) {
            // @ts-ignore
            if (this.arBasket[i].product.stocks[0].amount === 0) {
                this.showBtnSend = false
            }
            // @ts-ignore
            if (isNaN(this.arBasket[i].count) === true) {
                return this.nanSymbol = true
            } else {
                this.nanSymbol = false
            }
        }
    }

    getCount = (item: any) => {
        return item?.basket?.count ? item?.basket?.count : 1;
    }

    getCountFavorite = (item: any) => {
        return item?.count ? item?.count : 1;
    }

    @action
    setError = (error: any) => {
        if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message === "Сумма заказа для оплаты 0"
        ) {
            this.onTextAboutErrorWebPayLink()
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message === "Сумма заказа изменилась"
        ) {
            window.localStorage.setItem("order", "1")
            document.location.href = '/personal/order-history'
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message === "Сумма заказа для оплаты меньше 5 BYN"
        ) {
            this.isPopupWebPayAboutMinSum = true
            this.arBasket = []
            this.summAll = 0
            this.linkWebPay = ""
        } else if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message !== "Сумма заказа для оплаты меньше 5 BYN" &&
            error.response.data.message !== "Сумма заказа изменилась" &&
            error.response.data.message !== "Сумма заказа для оплаты 0"
        ) {
            alert(error.response.data.message)
            window.location.replace('/personal/order-history')
        }

        // this.onTextAboutErrorWebPayLink()

    }

    @action
    saveOrder = () => {
        let ob = this.getObOrder();
        OrderModel.addOrder({ ...ob, status: enumOrderStatus.draft }, this.setOrderSave, this.setError);
    }

    @action
    setOrderSave = () => {
        this.rootStore?.mainStore.setRedirectUrl('/basket-successful');
    }

    @action
    setOrderSend = (result: any) => {
        this.rootStore?.mainStore.getLoyaltyUpdateInfo()
        if (result.data.url && result.data.url.data && this.selectedOption.PaymentTypeId.value === APP_PAYMENT_TYPE_WEBPAY) {
            this.linkWebPay = result.data.url.data.redirectUrl
            // window.location.replace(result.data.url.data.redirectUrl);
        } else if (this.selectedOption.PaymentTypeId.value !== APP_PAYMENT_TYPE_WEBPAY) {
            this.error = '';
            // @ts-ignore
            this.rootStore.appStore.setPopupThankYouSendOrder(true);
        } else if (result.statusCode === 400) {
            console.log("400")
        }
    }

    getOpenModal2() {
        let modal = false;
        let thisDay = new Date();
        if (this.selectedOption.Dates.value) {
            let dateSelected = new Date(this.selectedOption.Dates.value);
            let timeDiff = Math.abs(dateSelected.getTime() - thisDay.getTime())
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (diffDays > 5) {
                modal = true;
            }
        }
        return modal;
    }

    @action
    sendOrder = (isModal: boolean) => {
        if (!this.isValid()) {
            return false;
        }
        if (isModal) {
            if (this.getOpenModal2()) {
                this.isModalOpen2 = true;
                return false;
            }
            if (this.selectedOption.Points.amount > this.summAll) {
                this.isModalOpen = true;
                return false;
            }
            if (this.selectedOption.Points.amount > this.summAll) {
                this.isModalOpen = true;
            } else {
                let ob = this.getObOrder();
                OrderModel.addOrder({ ...ob, status: enumOrderStatus.created }, this.setOrderSend, this.setError);
            }
        } else {
            let ob = this.getObOrder();
            OrderModel.addOrder({ ...ob, status: enumOrderStatus.created }, this.setOrderSend, this.setError);
        }
    }

    isValid() {
        let result = true;
        if (!this.selectedOption.Dates.value) {
            alert('Выберите дату доставки!');
        }
        if (!this.selectedOption.Points.value) {
            alert('Выберите торговую точку!');
        }
        if (!this.selectedOption.PaymentType.value) {
            alert('Выберите тип оплаты!');
        }
        return result;
    }


    @action
    onSubmitForm(e: { preventDefault: () => void; }) {
        this.openConfirm = false
        let dateconfirmpersonaldata = this.rootStore?.mainStore.getDateConfirmPersonalData();
        this.textAboutErrorWebPayLink = false
        e.preventDefault();
        let dateNow = moment(Date.now()).format("YYYY-MM-DD")
        let blockDate = '2023-04-17'
        if (!this.phone.length && this.selectedOption.DeliveryType.value === 2) {
            alert("Телефон является обязательным полем для заполнения")
            return
        }
        if (dateNow < blockDate && this.org_type_id === APP_ORG_TYPE_FIZLICO) {
            alert("Извините, но данный функционал временно недоступен")
        } else if (dateconfirmpersonaldata === null) {
            this.rootStore?.mainStore.setOpenConfirmConsent()
            this.openConfirm = true
            return
        } else {
            if (this.validate()) {
                let totalOrderSumm = this.summAll - this.scores
                if (this.selectedOption.Points.amount > totalOrderSumm &&
                    this.selectedOption.DeliveryType.value === APP_DELIVERY_TYPE_COURIER &&
                    this.org_type_id === APP_ORG_TYPE_FIZLICO) {
                    // @ts-ignore
                    this.rootStore.appStore.setPopupSendOrder(true)
                } else if (this.getOpenModal2()) {
                    this.isModalOpen2 = true;
                    return false;
                } else {
                    let ob = this.getObOrder();
                    if (this.selectedOption.PaymentTypeId.value === APP_PAYMENT_TYPE_WEBPAY) {
                        if (this.summAll < 5) {
                            this.isPopupWebPayAboutMinSum = true
                            return false
                        } else {
                            setTimeout(this.onRemoveLink, 2000000);
                            // setTimeout(this.onTextAboutErrorWebPayLink, 10000);
                            this.isPopupWebPay = true
                            OrderModel.addOrder({
                                ...ob,
                                status: enumOrderStatus.created
                            }, this.setOrderSend, this.setError);
                        }
                    } else {
                        OrderModel.addOrder({ ...ob, status: enumOrderStatus.created }, this.setOrderSend, this.setError);
                    }
                }
            }
        }
        return false;
    }

    @action
    validate() {
        let result = true;
        for (let code in this.selectedOption) {
            // @ts-ignore
            let item = this.selectedOption[code];
            // @ts-ignore
            if (code === 'Client' || code === 'DeliveryType' || code === 'Points') {
                if (item.value > 0) {

                } else {
                    this.error = 'Заполните обязательные поля';
                    result = false;
                }
            }
            if (code === 'Dates' && item.value === '') {
                this.error = 'Заполните обязательные поле Дата доставки';
                result = false;
            }
            if (code === 'PaymentTypeId' && item.value === 0) {
                this.error = 'Заполните обязательные поле Способ оплаты';
                result = false;
            }
        }
        return result;
    }

    getObOrder = () => {
        if (this.selectedOption.DeliveryType.value !== APP_DELIVERY_TYPE_COURIER) {
            this.selectedOption.ClientShipDates.value = ""
        }
        if (this.org_type_id !== APP_ORG_TYPE_FIZLICO) {
            this.selectedOption.ClientShipDates.value = ""
        }
        return {
            blockReason: '',
            clientId: this.selectedOption.Client.value,
            deliveryTimeFrom: '08:00:00',
            deliveryTimeTo: '16:00:00',
            deliveryTypeId: this.selectedOption.DeliveryType.value,
            ex_id: uuidv4(),
            isProcessed: false,
            note: this.selectedOption.Note.value,
            notJoin: false,
            orderSum: this.summAll,
            scaleDiscountByPaymentDateId: this.selectedOption.PaymentType.value,
            shipDate: this.selectedOption.Dates.value,
            siteId: APP_SITE_ID,
            status: enumOrderStatus.created,
            tradePointId: this.selectedOption.Points.value,
            userId: this.rootStore?.mainStore.id,
            formOfPayment: this.selectedOption.MethodPay.value,
            paymentType: this.selectedOption.PaymentTypeId.value,
            contact: this.name,
            contactPhone: this.phone,
            clientShipDates: this.selectedOption.ClientShipDates.label,
            scoresDebt: this.scores,
            bonusAmount: this.scores
        }
    }

    @action
    onCloseModal = () => {
        this.isModalOpen = false;
    }

    @action
    onCloseModal2 = () => {
        this.isModalOpen2 = false;
    }

    @action
    onCloseModalWebPay = () => {
        window.localStorage.setItem("order", "1")
        this.arBasket = []
        this.summAll = 0
        this.isPopupWebPay = false;
        this.textAboutErrorWebPayLink = false
    }

    @action
    onRemoveLink = () => {
        this.linkWebPay = "";
        this.isPopupWebPay = false;
    }

    @action
    onTextAboutErrorWebPayLink = () => {
        this.textAboutErrorWebPayLink = true
    }

    @action
    onCloseModalWebPayAboutMinSum = () => {
        this.isPopupWebPayAboutMinSum = false
    }

    @action
    getFaforiteAndBasketCountForIconOnTheTopPage = () => {
        if (this.rootStore?.mainStore.isAuth) {
            let item = this.rootStore?.mainStore.clientId
            this.rootStore?.pageCatalogStore.getFaforite(item)
            BasketModel.getBasket(item, 0, this.setBasket, this.setError);
        }
    }

    @action
    productMinusScores = (minus: number) => {
        if (this.showMessageAboutBlockAction) {
            alert("Данный товар не учавствует в программе лояльности")
        } else {
            let newValue = this.scores
            if (newValue >= 0.1) {
                this.scores = newValue - minus
                this.scores = parseFloat(this.scores.toFixed(2));
                let clientId = this.rootStore?.mainStore.getClientId();
                let discount = this.selectedOption?.PaymentType?.value;
                let scores = this.scores
                BasketModel.getFutureLoyaltyScores(clientId, discount, scores, this.setLoyaltyScores, this.setError)
            } else if (newValue < 0.1 && newValue > 0) {
                this.scores = newValue - 0.01
                this.scores = parseFloat(this.scores.toFixed(2));
                let clientId = this.rootStore?.mainStore.getClientId();
                let discount = this.selectedOption?.PaymentType?.value;
                let scores = this.scores
                BasketModel.getFutureLoyaltyScores(clientId, discount, scores, this.setLoyaltyScores, this.setError)
            }
        }
    }

    @action
    setLoyaltyScores = (result: any) => {
        if (result && result.data && result.data[0]) {
            this.futureScores = result.data[0].scores
        }
    }

    @action changeScoreInput = (e: { target: { value: string } }) => {
        let scoresAmount = this.rootStore?.mainStore.scoresAmount
        if (this.showMessageAboutBlockAction) {
            alert("В корзине отсутствуют товары из каталога бонусных товаров")
        } else {
            let value = e.target.value
            if (Number(value) > Number(scoresAmount)) {
                return alert(`Максимальное количество бонусов для Вашей корзины ${scoresAmount}`)
            }
            if (Number(value) > this.scoresConst) {
                return alert(`Максимальное количество бонусов для Вашей корзины ${this.scoresConst}`)
            }
            if (this.rootStore?.mainStore.scoresAmount && Number(value) <= this.rootStore?.mainStore.scoresAmount) {
                this.scores = Number(value)
            } else {
                alert("столько баллов у Вас нет")
            }
        }
    }

    @action
    productPlusScores = (plus: number) => {
        let newValue = this.scores
        let plusValue = 0
        let check = this.scoresConst - newValue
        if (this.showMessageAboutBlockAction) {
            alert("В корзине отсутствуют товары из каталога бонусных товаров")
        } else {
            if (newValue < Number(this.rootStore?.mainStore.scoresAmount) && check > 0.1) {
                plusValue = plus
            } else {
                plusValue = 0.01
            }
            if (newValue + plusValue > this.scoresConst) {
                return alert(`Максимальное количество бонусов для Вашей корзины ${this.scoresConst}`)
            }
            // @ts-ignore
            if (newValue < Number(this.rootStore?.mainStore.scoresAmount)) {
                this.scores = newValue + plusValue
                this.scores = parseFloat(this.scores.toFixed(2));
                let clientId = this.rootStore?.mainStore.getClientId();
                let discount = this.selectedOption?.PaymentType?.value;
                let scores = this.scores
                BasketModel.getFutureLoyaltyScores(clientId, discount, scores, this.setLoyaltyScores, this.setError)
            }
        }
    }

}
